import { useEffect, useState } from "react";
import {
  ruRU,
  GridColDef,
  GridToolbarExport,
  DataGrid,
  GridToolbarContainer,
  GridValueGetterParams,
  GridSelectionModel,
  gridClasses,
} from "@mui/x-data-grid";
import { Stack, Button, Alert } from "@mui/material";
import * as pdfjsLib from "pdfjs-dist";
import _ from "lodash";
import { TextItem } from "pdfjs-dist/types/src/display/api";
import { Order } from "../../../types/order";
import { LoadingButton } from "@mui/lab";
import { useTelegram } from "../../../hooks/useTelegram";


const tableFields = [
  "No п/п",
  "No накладной",
  "Номер отправления ИМ",
  "Дата накладной",
  "Дата доставки",
  "Получатель",
  "Стоимость основной услуги (руб)",
  "Доп. услуги",
  "Стоимость доп. услуг (руб)",
  "Сумма наложенно го платежа (руб)",
  "Размер посреднического вознаграждения (руб)",
  "Итого услуги доставки (руб)",
];

type fieldType = {
  name: string;
  index: number;
  xCenter: number;
  yOffset: number;
};

type CdekDataType = {
  [id: string]: string;
};
//Погрешность расположения эелементов таблицы в пикселях
const errorPosition = 5;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Stack direction={"row"} gap={"20px"}>
        <GridToolbarExport
          // csvOptions={{ disableToolbarButton: true }}
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
            copyStyles: true,
          }}
        />
      </Stack>
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "№ п/п",
    minWidth: 60,
    flex: 0.05,
    filterable: false,
    renderCell: (index) => index.api.getRowIndex(index.row["No накладной"]) + 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cdekNumber",
    headerName: "№ накладной",
    minWidth: 110,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) => params.row["No накладной"],
  },

  {
    field: "createdAt",
    headerName: "Дата накладной",
    minWidth: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Дата накладной"],
    headerAlign: "center",
    flex: 0.12,
    align: "center",
  },
  {
    field: "dateDelivered",
    headerName: "Дата доставки",
    minWidth: 100,
    flex: 0.12,
    align: "center",
    valueGetter: (params: GridValueGetterParams) => params.row["Дата доставки"],
    headerAlign: "center",
  },
  {
    field: "nameRecipient",
    headerName: "Получатель",
    flex: 0.15,
    minWidth: 150,
    headerAlign: "center",
    valueGetter: (params: GridValueGetterParams) => params.row["Получатель"],
  },
  {
    field: "deliveryPrice",
    headerName: "Стоимость основной услуги (руб)",
    minWidth: 100,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Стоимость основной услуги (руб)"],
  },
  {
    field: "priceCOD",
    headerName: "Сумма наложенного платежа (руб)",
    minWidth: 110,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Сумма наложенно го платежа (руб)"],
  },
  {
    field: "price",
    headerName: "Стоимость доп. услуг (руб)",
    minWidth: 100,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Стоимость доп. услуг (руб)"],
  },
  {
    field: "priceCashDelivery",
    headerName: "Сумма наложенно го платежа (руб)",
    minWidth: 100,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Сумма наложенно го платежа (руб)"],
  },
  {
    field: "priceReward",
    headerName: "Размер посреднического вознаграждения (руб)",
    minWidth: 100,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Размер посреднического вознаграждения (руб)"],
  },
  {
    field: "priceResult",
    headerName: "Итого услуги доставки (руб)",
    minWidth: 100,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
    valueGetter: (params: GridValueGetterParams) =>
      params.row["Итого услуги доставки (руб)"],
  },
];

interface ICdekReportPageProps {
  selectedOrderNumber: string;
  currentReportOrders: Order[];
}

export const CdekReportPage = ({
  selectedOrderNumber,
  currentReportOrders,
}: ICdekReportPageProps) => {
  const [cdekData, setCdekData] = useState<CdekDataType[]>([]);
  const [cdekDataCopy, setCdekDataCopy] = useState<CdekDataType[]>([]);
  const [isShowCdekData, setIsShowCdekData] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridSelectionModel>([]);
  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useTelegram();

  async function handleImageUpload(e: any) {
    setIsDataLoading(true);
    var file = e.target.files[0];
    if (file.type !== "application/pdf") {
      setError("Выберtте файл формата pdf.");
      setIsDataLoading(false);
      setTimeout(() => setError(""), 3000);
      return;
    }
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

    const fileReader = new FileReader();

    fileReader.onload = (function (f) {
      return async function (e) {
        var typedarray = new Uint8Array(e.target?.result as ArrayBuffer);

        pdfjsLib
          .getDocument(typedarray)
          .promise.then(async function (pdf) {
            let items: TextItem[] = [];
            for (let page = 1; page <= pdf.numPages; page++) {
              const data = await pdf.getPage(page);
              let content = await data.getTextContent();
              items = [...items, ...(content.items as TextItem[])];
            }

            // Склевание заголовков с перенесенными строками
            const resultItems: TextItem[] = [];
            let i = 0;
            while (i < items.length) {
              let j = i;
              while (
                items[i].hasEOL &&
                items[j + 1].hasEOL &&
                items[i].transform[5] > items[j + 1].transform[5]
              ) {
                items[i].str += " " + items[j + 1].str;
                j++;
              }
              items[i].hasEOL = false;
              resultItems.push(items[i]);
              i = j + 1;
            }

            // Поиск  заданных заголовков таблицы
            const parametersFields: fieldType[] = tableFields.map((field) => {
              const itemField = resultItems.find((item) => item.str === field);
              if (!itemField) {
                return {
                  name: field,
                  index: 0,
                  xCenter: 0,
                  yOffset: 0,
                };
              }
              const itemFieldIndex = resultItems.findIndex(
                (item) => item === itemField
              );
              return {
                name: field,
                index: itemFieldIndex,
                xCenter: itemField?.transform[4] + itemField?.width / 2,
                yOffset: itemField?.transform[5],
              };
            });

            // Поиск индекса эемента начала таблицы
            let startTableDataIndex = 0;
            parametersFields.forEach((field) => {
              if (field.index && field.index > startTableDataIndex) {
                startTableDataIndex = field.index;
              }
            });

            let rows = [];
            let row: CdekDataType = {};
            i = startTableDataIndex + 1;

            // Поиск элементов таблицы
            for (
              let i = startTableDataIndex + 1;
              i < resultItems.length - 1;
              i++
            ) {
              const currentField = parametersFields.find(
                (field) =>
                  field.xCenter - errorPosition <
                    resultItems[i].transform[4] + resultItems[i].width / 2 &&
                  resultItems[i].transform[4] + resultItems[i].width / 2 <
                    field.xCenter + errorPosition
              );
              if (!currentField) {
                continue;
              }
              row[currentField.name] = resultItems[i].str;
              if (
                resultItems[i].transform[5] - resultItems[i + 1].transform[5] >
                  errorPosition ||
                resultItems[i].transform[5] - resultItems[i + 1].transform[5] <
                  -errorPosition
              ) {
                rows.push(_.cloneDeep(row));
                row = {};
              }
            }

            // Фильтрация найденных строк таблицы
            const cdekData = rows.filter((row) => {
              if (row["No накладной"]) {
                if (isNaN(Number(row["No накладной"]))) {
                  return false;
                }
                return row["No накладной"].length > 5;
              } else {
                return false;
              }
            });

            setCdekData(cdekData);
            setCdekDataCopy(cdekData);
            setIsDataLoading(false);

            if (cdekData.length === 0) {
              setError("Неверный формат файла");
              setTimeout(() => setError(""), 3000);
            }
          })
          .catch((error) => {
            setError("При загрузке произошла ошибка.");
            setTimeout(() => setError(""), 3000);
          });
      };
    })(file);

    fileReader.readAsArrayBuffer(file);
  }

  const filterData = () => {
    if (isDataFiltered) {
      setCdekData(cdekDataCopy);
      setIsDataFiltered(false);
      return;
    }
    setCdekData((prev) => {
      return prev.filter((order) =>
        currentReportOrders.some(
          (currentOrdder) => currentOrdder.cdekNumber === order["No накладной"]
        )
      );
    });
    setIsDataFiltered(true);
  };

  const onClickBack = () => {
    window.history.go(-1);
  }

  useEffect(() => {
    if (
      cdekData.some(
        (currentOrdder) => selectedOrderNumber === currentOrdder["No накладной"]
      )
    ) {
      setRowSelectionModel([selectedOrderNumber]);
    } else {
      setRowSelectionModel([]);
    }
  }, [selectedOrderNumber]);


  return (
    <div
      style={{
        display: "flex",
        height: isShowCdekData ? "50vh" : "auto",
        marginBottom: isShowCdekData ? "80px" : "0",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <LoadingButton
          variant="contained"
          component="label"
          sx={{ mt: 2, mb: "20px" }}
          loading={isDataLoading}
        >
          Загрузить таблицу
          <input
            type="file"
            hidden
            accept="application/pdf"
            multiple
            onChange={(evt) => handleImageUpload(evt)}
          />
        </LoadingButton>
        {user && <Button
            variant="outlined"
            component="label"
            sx={{ mt: 2, ml: 1, mb: "20px" }}
            onClick={onClickBack}
          >
            Назад
          </Button>}
        {cdekData.length !== 0 && (
          <Button
            variant="outlined"
            component="label"
            sx={{ mt: 2, ml: 1, mb: "20px" }}
            disabled={cdekData.length === 0}
            onClick={() => setIsShowCdekData(!isShowCdekData)}
          >
            {isShowCdekData ? "Скрыть таблицу" : "Показать таблицу"}
          </Button>
        )}
        {isShowCdekData && (
          <Button
            variant="outlined"
            component="label"
            sx={{ mt: 2, ml: 1, mb: "20px" }}
            onClick={filterData}
          >
            {isDataFiltered ? "Все данные" : "Фильтровать"}
          </Button>
        )}
        {isShowCdekData ? (
          <DataGrid
            rows={cdekData}
            columns={columns}
            rowsPerPageOptions={[]}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Toolbar: () => CustomToolbar(),
            }}
            onSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            getRowHeight={() => "auto"}
            selectionModel={rowSelectionModel}
            getRowId={(row) => row["No накладной"]}
            initialState={{
              sorting: {
                sortModel: [{ field: "dateDelivered", sort: "desc" }],
              },
            }}
            sx={{
              '@media print': {
                '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)', overflow: 'visible', contain: "size", zoom: "0.92"},
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-cell:click": {
                color: "primary.main",
              },
              "& .MuiDataGrid-row:hover": {
                color: "primary.main",
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell": {
                height: "100px",
                padding: "0px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
              [`& .${gridClasses.cell}`]: {
                py: "1px",
              },
            }}
          />
        ) : null}
      </div>
      {error && (
        <Alert className="alert-request" variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </div>
  );
};
