import { enqueueSnackbar } from 'notistack';

export const showErrorNotification = (messages: string[] | string) => {
  if (Array.isArray(messages)) {
    messages.forEach((msg) => {
      enqueueSnackbar(msg, {
        variant: 'error',
      });
    });
  }
  enqueueSnackbar(messages, {
    variant: 'error',
  });
};

export const showSuccessNotification = (messages: string[] | string) => {
  if (Array.isArray(messages)) {
    messages.forEach((msg) => {
      enqueueSnackbar(msg, {
        variant: 'success',
      });
    });
  }
  enqueueSnackbar(messages, {
    variant: 'success',
  });
};
