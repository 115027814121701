import orderPageSlice from "./OrderPageSlice/orderPageSlice";
import { configureStore } from "@reduxjs/toolkit";
import { createApi } from "../services/api";
import createOrderSlice from "./CreateOrderSlice/createOrderSlice";
import userPageSlice from "./UserPageSlice/userPageSlice";
import userSlice from "./UserSlice/userSlice";
import reportSlice from "./ReportSlice/reportSlice";

const api = createApi();

export const store = configureStore({
  reducer: {
    order: createOrderSlice,
    orderPage: orderPageSlice,
    userPage: userPageSlice,
    user: userSlice,
    report: reportSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
    }),
});
