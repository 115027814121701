import { Button } from "@mui/material";
import accessTokenController from "../../../services/accessTokenController";
import { useTelegram } from "../../../hooks/useTelegram";
import Cookies from "js-cookie";

function LogoutButton() {
  const { user, tg } = useTelegram();

  const onClick = () => {
    if (user) {
      Cookies.remove("access_token");
      tg.close();
      return;
    }
    accessTokenController.logout();
  };
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={onClick}
      sx={{ mb: "20px", mr: "10px", ml: "10px" }}
    >
      Выйти
    </Button>
  );
}

export default LogoutButton;
