import { CustomMarker } from "./CustomMarker";
import { useAppSelector } from "../../hooks/redux";
import { getOfficeListLocationTo } from "../../redux/CreateOrderSlice/selector";

export const CustomMarkerList = () => {
  const officeListLocationTo = useAppSelector(getOfficeListLocationTo);

  if (!officeListLocationTo) {
    return null;
  }

  return officeListLocationTo?.map((el: any) => (
    <CustomMarker key={el.code} pointData={el} />
  ));
};
