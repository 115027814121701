import { DataGrid, GridColDef, GridRowParams, ruRU } from '@mui/x-data-grid';
import { typedMemo } from '../../../utils/helpers/typedMemo';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';

interface TableProps<T> {
  columns: GridColDef[];
  items: T[];
  idField: string;
  handleProductListItemClick: (row: GridRowParams<any>) => void;
  fetchDataCallback: (page: number, pageSize: number) => void;
  totalCount: number;
  isLoading: boolean;
  getRowHeight?: () => any;
}

export const Table = typedMemo(
  <T,>({
    columns,
    items,
    idField,
    handleProductListItemClick,
    fetchDataCallback,
    totalCount,
    isLoading,
    getRowHeight = () => 'auto',
  }: TableProps<T>) => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState(0);

    const onChangePageSize = (newPageSize: number) => {
      setPageSize(newPageSize);
      fetchDataCallback(page, pageSize);
    };

    const onChangePage = (page: number) => {
      setPage(page);
      fetchDataCallback(page, pageSize);
    };

    if (isLoading) {
      return (
        <TailSpin
          color='#2274a5'
          wrapperStyle={{ margin: '300px', justifyContent: 'center' }}
        />
      );
    }

    return (
      <DataGrid
        rows={items}
        columns={
          columns
          // role !== "ADMIN" ? basicColumns : basicColumns.concat(extraColumns)
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'asc' }],
          },
        }}
        getRowHeight={getRowHeight}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onRowClick={handleProductListItemClick}
        getRowId={(row) => row[idField]}
        pageSize={pageSize}
        onPageSizeChange={onChangePageSize}
        onPageChange={onChangePage}
        rowCount={totalCount}
        page={page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        paginationMode='server'
        // RF - вынести в переменную, вне компонента в этом же файле
        sx={{
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:click': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1,
          },
        }}
      />
    );
  }
);
