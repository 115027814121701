import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Report } from "../../types/report";

interface ReportState {
  reports: Report[];
  error: string;
  isLoading: boolean;
  total: number;
}

const initialState: ReportState = {
  reports: [],
  error: "",
  isLoading: false,
  total: 0,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReports(state, action: PayloadAction<Report[]>) {
      state.reports = action.payload;
    },
    addReport(state, action: PayloadAction<Report>) {
      state.reports.push(action.payload);
    },
    setErrorReports(state, action) {
      state.error = action.payload;
    },
    resetErrorReports(state) {
      state.error = "";
    },
    setIsLoadingReports(state, action) {
      state.isLoading = action.payload;
    },
    setTotalReports(state, action: PayloadAction<number>) {
      state.total = action.payload;
    }
  },
});

export const {
  setReports,
  addReport,
  setErrorReports,
  resetErrorReports,
  setIsLoadingReports,
  setTotalReports,
} = reportSlice.actions;

export default reportSlice.reducer;
