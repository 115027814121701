import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type CreateNewOrderStepperProps = {
  steps: string[];
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  disableNext: boolean;
};

const CreateNewOrderStepper = ({
  steps,
  activeStep,
  handleNext,
  handleBack,
  disableNext,
}: CreateNewOrderStepperProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={{ width: "100%", marginTop: "auto" }}>
      <Stepper
        activeStep={activeStep}
        sx={{ mt: matches ? "0" : "20px" }}
        orientation={matches ? "horizontal" : "vertical"}
      >
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <>
        <Box sx={{ display: "flex", flexDirection: "row", p: 1, pb: '25px' }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Назад
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={handleNext}
            disabled={activeStep === steps.length || disableNext}
          >
            Далее
          </Button>
        </Box>
      </>
    </Box>
  );
};

export default CreateNewOrderStepper;
