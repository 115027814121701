import { Button } from "@mui/material";

type NavItemProps = {
  onOpen: () => void;
  title: string;
  open?: boolean;
};

function NavItemButton({ onOpen, title, open }: NavItemProps) {
  return (
    <Button
      variant="outlined"
      color="success"
      onClick={onOpen}
      sx={{
        display: open ? "none" : "inherit",
      }}
    >
      {title}
    </Button>
  );
}

export default NavItemButton;
