import { DimensionsType } from "../types/order";

export const getOrderDimensions = (point: any) => {
    const orderDimensions: DimensionsType = {};
  
    const { weight_max, weight_min, dimensions } = point;
    orderDimensions.weightMax = weight_max;
    orderDimensions.weightMin = weight_min;
  
    if(dimensions && dimensions[0]) {
      const { height, width, depth} = dimensions[0];
      orderDimensions.height = height;
      orderDimensions.width = width;
      orderDimensions.depth = depth;
    }
  
    return orderDimensions;
  }