import { Stack, Typography } from "@mui/material";
import CreateNewReport from "../../CreateNewReport/CreateNewReport";
import { WithAdminAccess } from "../../hoc/WithAdminAccess";
import { ReportsList } from "../../ReportsList/ReportsList";

const ReportsPage = () => {
  return (
    <section>
      <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
        <Typography variant="h4">Отчеты</Typography>
        <CreateNewReport/>
      </Stack>
      <ReportsList/>
    </section>
  );
};

export default WithAdminAccess(ReportsPage, true);