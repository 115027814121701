import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import CreateNewOrder from '../../CreateNewOrder/CreateNewOrder';
import { OrdersList } from '../../OrderList/OrderList';
import {
  updateOrderCdekNumber,
  updateOrderStatus,
} from 'redux/OrderPageSlice/orderPageSlice';
import { socket } from 'services/socket';

const OrdersPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.on('order:status', ({ status, cdekNumber, cdekOrderId }) => {
      dispatch(updateOrderStatus({ status, cdekOrderId }));
      dispatch(updateOrderCdekNumber({ cdekNumber, cdekOrderId }));
    });

    return () => {
      socket.off('order:status');
      socket.off('report:ready');
    };
  }, []);

  return (
    <section>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant='h4'>Заказы</Typography>
        <Stack direction={'row'} spacing={3}>
          <CreateNewOrder />
        </Stack>
      </Stack>
      <OrdersList />
    </section>
  );
};

export default OrdersPage;
