import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { apiErrorHandler } from '../../services/apiErrorHandle';
import { AppDispatch, State } from '../../types/redux';
import {
  resetErrorReports,
  setErrorReports,
  setIsLoadingReports,
  setReports,
  setTotalReports,
} from './reportSlice';
import { ApiRoutes } from '../../consts/api';

interface FetchReportsActionI {
  page?: number;
  limit?: number;
}

export const fetchReportsAction = createAsyncThunk<
  void,
  FetchReportsActionI,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  'reports/fetchReports',
  async ({ page = 0, limit = 10 }, { dispatch, extra: api }) => {
    dispatch(setIsLoadingReports(true));
    dispatch(resetErrorReports());

    try {
      const { data } = await api.get(ApiRoutes.GetReports, {
        params: { page, limit },
      });

      if (data?.reports) {
        dispatch(setTotalReports(data.total));
        dispatch(setReports(data.reports));
        dispatch(setIsLoadingReports(false));
      }
    } catch (error) {
      dispatch(setIsLoadingReports(false));
      dispatch(setErrorReports(apiErrorHandler(error)));
    }
  }
);
