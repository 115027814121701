export const ApiRoutes = {
  SignIn: "/api/auth/signin",
  CreateOrder: "/api/order/create",
  PrintOrderForm: "/api/form/create",
  Barcode: "/api/barcode",
  PrintBarcodeWebApp: "/api/webApp/barcode",
  PrintBarcode: "/api/barcode/print",
  GetOrders: "/api/order",
  DeleteOrder: "/api/order",
  getTariffs: "/api/order/tariffs",
  User: "/api/user",
  GetOfficeList: "/api/order/offices",
  GetPrintFormPdf: "/api/form/pdf",
  GetPrintFormPdfWebApp: "/api/webApp/form",
  GetReports: "/api/reports",
  CreateReport: "/api/reports/create",
  UpdateOrderPaymentStatus: "/api/order/paymentStatus",
  UpdateOrder: "/api/order",
  getCitiesSdekCode: "/api/order/city/code",
};

export enum RequestResultStatus {
  Success = "SUCCESS",
  Fail = "FAIL",
  Unknown = "UNKNOWN",
}

export enum UnauthorizedUserResponse {
  Status = 401,
  Text = "Unauthorized",
}
