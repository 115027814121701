import {
  DataGrid,
  GridRowParams,
  GridSelectionModel,
  ruRU,
  GridComparatorFn,
} from '@mui/x-data-grid';
import { useState } from 'react';
import {
  TariffOrderType,
  TariffType,
  TariffTypeCamel,
} from '../../../types/order';
import { useEffect } from 'react';
import { fetchTariffList } from '../../../services/api';
import { TailSpin } from 'react-loader-spinner';
import { getMarginPriceFromString } from '../../../utils';
import { Box, FormLabel } from '@mui/material';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import useMediaQuery from '@mui/material/useMediaQuery';

const deliverySumComparator: GridComparatorFn<string> = (value1, value2) => {
  return getMarginPriceFromString(value1) - getMarginPriceFromString(value2);
};

const tariffColumns = [
  {
    field: 'tariff_name',
    headerName: 'Тип услуги',
    minWidth: 120,
    flex: 0.5,
  },
  {
    field: 'delivery_sum',
    headerName: 'Стоимость',
    minWidth: 80,
    flex: 0.3,
    sortComparator: deliverySumComparator,
  },
  {
    field: 'time',
    headerName: 'Срок',
    flex: 0.2,
    valueGetter: ({ row }: { row: TariffType }) => {
      return `${row.period_min} - ${row.period_max} дней`;
    },
  },
];

type CreateNewOrderFormTariffProps = {
  tariff: TariffType | TariffTypeCamel | null;
  setTariff: (value: TariffType) => void;
  orderTariffData: TariffOrderType;
  errors: Record<string, string>;
};

const CreateNewOrderFormTariff = ({
  setTariff,
  tariff,
  orderTariffData,
  errors,
}: CreateNewOrderFormTariffProps) => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [tariffs, setTariffs] = useState<TariffType[] | null>(null);
  const matches = useMediaQuery('(min-width:450px)');

  useEffect(() => {
    fetchTariffList(orderTariffData)
      .then(({ data }) => {
        setTariffs(
          data?.tariff_codes?.sort(
            (a: any, b: any) => a.delivery_sum - b.delivery_sum
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (tariff) {
      if ('tariff_code' in tariff) {
        setSelectionModel([tariff.tariff_code]);
      } else {
        setSelectionModel([tariff.tariffCode]);
      }
    }
  }, []);

  const handleProductListItemClick = (params: GridRowParams) => {
    if (params.row) {
      setTariff(params.row);
    }
  };

  if (!tariffs) {
    return (
      <TailSpin
        color='#2274a5'
        wrapperStyle={{ margin: '300px', justifyContent: 'center' }}
      />
    );
  }

  if (tariffs.length === 0) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity='error'>
          По данному направлению отправка невозможна
        </Alert>
      </Stack>
    );
  }

  return (
    <Box sx={{ minHeight: '40vh', marginBottom: '30px' }}>
      <FormLabel id='tariff' sx={{ marginBottom: '10px', display: 'block' }}>
        Список тарифов:
      </FormLabel>
      <DataGrid
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        rows={tariffs}
        getRowId={(row) => row.tariff_code}
        columns={tariffColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowHeight={matches ? () => undefined : () => 'auto'}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        onRowClick={handleProductListItemClick}
        sx={{
          getRowHeight: `() => "auto"`,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:click': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}
      />
      {errors?.tariff && (
        <span style={{ marginTop: '20px', color: 'red', textAlign: 'center' }}>
          {errors?.tariff}
        </span>
      )}
    </Box>
  );
};

export default CreateNewOrderFormTariff;
