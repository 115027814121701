import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IAutocomplitingAddress,
  PackageSettingType,
  PackagesSettingType,
} from '../../types/order';
import { getDefaultPackage } from './lib/order';

// Define a type for the slice state
interface OrderState {
  value: number;
  locationTo: IAutocomplitingAddress;
  locationFrom: IAutocomplitingAddress;
  officeListLocationTo: any[] | null;
  officeListLocationFrom: any[] | null;
  isOrderPayed: boolean;
  packages: PackagesSettingType;
}

// Define the initial state using that type
const initialState: OrderState = {
  value: 0,
  packages: getDefaultPackage(),
  locationTo: {
    value: '',
    fiasCode: '',
    lat: '',
    lon: '',
    postalCode: null,
    sdekCityCode: null,
    deliveryPointCode: '',
    deliveryPointName: '',
    deliveryPointAdress: '',
    deliveryPointOpenHours: '',
    pickupType: '',
  },
  locationFrom: {
    value: '',
    fiasCode: '',
    lat: '',
    lon: '',
    postalCode: null,
    sdekCityCode: null,
    deliveryPointAdress: '',
    deliveryPointOpenHours: '',
    pickupType: '',
  },
  officeListLocationTo: null,
  officeListLocationFrom: null,
  isOrderPayed: false,
};

export const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    setLocationTo: (state, action) => {
      console.log(action.payload, 'action.payload');
      
      state.locationTo = {
        ...state.locationTo,
        ...action.payload,
      };
    },

    setLocationFrom: (state, action) => {
      state.locationFrom = {
        ...state.locationFrom,
        ...action.payload,
      };
    },

    setOfficeListLocationFrom: (state, action) => {
      state.officeListLocationFrom = action.payload;
    },

    setOfficeListLocationTo: (state, action) => {
      state.officeListLocationTo = action.payload;
    },

    setPackages: (state, action: PayloadAction<PackagesSettingType>) => {
      state.packages = action.payload;
    },
    updatePackageField: (
      state,
      action: PayloadAction<{
        packageId: string;
        fieldName: keyof PackageSettingType;
        value: any;
      }>
    ) => {
      const { packageId, fieldName, value } = action.payload;

      if (state.packages[packageId]) {
        state.packages[packageId][fieldName] = value;
      }
    },
  },
});

export const {
  setLocationTo,
  setLocationFrom,
  setOfficeListLocationFrom,
  setOfficeListLocationTo,
  setPackages,
  updatePackageField
} = createOrderSlice.actions;

export default createOrderSlice.reducer;
