import { ObjectSchema, AnyObject, Maybe } from 'yup';

type ValidationSchemaType = ObjectSchema<Maybe<AnyObject>, AnyObject, any, ''>;
export const getValidationError = <T>(
  schema: ValidationSchemaType,
  data: T
) => {
  const errorMessages: Record<string, string> = {};
  try {
    schema.validateSync(data, { abortEarly: false });
  } catch (error: any) {
    error.inner.forEach((e: any) => {
      errorMessages[e.path] = e.message;
    });
  }

  return errorMessages;
};
