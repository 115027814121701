import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  setOfficeListLocationFrom,
  setOfficeListLocationTo,
} from '../../../redux/CreateOrderSlice/createOrderSlice';
import {
  getLocationFrom,
  getLocationTo,
} from '../../../redux/CreateOrderSlice/selector';
import { getOfficeListBySdekCityCode } from '../../../services/api';
import WidgetMap from '../../Map/Map';
import Alert from '@mui/material/Alert';

type CreateNewOrderDeliveryInfoProps = {
  errors: Record<string, string>;
};

const CreateNewOrderDeliveryInfo = ({
  errors,
}: CreateNewOrderDeliveryInfoProps) => {
  const locationTo = useAppSelector(getLocationTo);
  const locationFrom = useAppSelector(getLocationFrom);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getOfficeListBySdekCityCode(locationFrom?.sdekCityCode).then(
      (officeListFrom) => {
        dispatch(setOfficeListLocationFrom(officeListFrom));
      }
    );
  }, [locationFrom, dispatch]);

  useEffect(() => {
    getOfficeListBySdekCityCode(locationTo?.sdekCityCode).then(
      (officeListTo) => {
        dispatch(setOfficeListLocationTo(officeListTo));
      }
    );
  }, [dispatch, locationTo?.sdekCityCode]);

  if (!locationTo) {
    return null;
  }  

  const { dimensions } = locationTo;

  return (
    <>
      <Typography variant='h5' component='h5' sx={{ textAlign: 'center' }}>
        Пункт выдачи заказа:
      </Typography>
      <Typography variant='h6' sx={{ textAlign: 'center' }}>
        {locationTo.deliveryPointName}
      </Typography>
      <Typography variant='body2' sx={{ textAlign: 'center' }}>
        {locationTo.deliveryPointAdress}
      </Typography>
      <Typography variant='body2' sx={{ textAlign: 'center' }}>
        {locationTo.deliveryPointOpenHours}
      </Typography>
      <Typography variant='h6' component='h6' sx={{ textAlign: 'center' }}>
        Требования к отправлению:
      </Typography>
      <Typography variant='body2' sx={{ textAlign: 'center', maxWidth: '300px', m: '0 auto' }}>
        {dimensions?.height && `Высота: ${dimensions?.height}; `}
        {dimensions?.width && `Ширина: ${locationTo.dimensions?.width}; `}
        {dimensions?.depth && `Глубина: ${locationTo.dimensions?.depth}; `}
        {dimensions?.weightMax &&
          `Макс. вес: ${locationTo.dimensions?.weightMax}; `}
        {dimensions?.weightMin !== undefined &&
          `Мин. вес: ${locationTo.dimensions?.weightMin}; `}
      </Typography>
      {Boolean(errors.map) && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity='error'>{errors.map}</Alert>
        </Stack>
      )}

      <WidgetMap />
    </>
  );
};

export default CreateNewOrderDeliveryInfo;
