import { Box, SxProps, Theme } from "@mui/material";

type SvgColorProps = {
  src: string;
  sx: SxProps<Theme>;
};

const SvgColor = ({ src, sx }: SvgColorProps) => (
  <Box
    component="span"
    className="svg-color"
    sx={{
      width: 24,
      height: 24,
      display: "inline-block",
      bgcolor: "currentColor",
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
  />
);

export default SvgColor;
