import { State } from '../../types/redux';

export const getLocationTo = (state: State) => state.order.locationTo;

export const getLocationFrom = (state: State) => state.order.locationFrom;

export const getOfficeListLocationTo = (state: State) =>
  state.order.officeListLocationTo;

export const getOfficeListLocationFrom = (state: State) =>
  state.order.officeListLocationFrom;

export const getPackages = (state: State) => state.order.packages;
