import { UserType } from "./../../types/user";
import { State } from "../../types/redux";

export const getUsers = (state: State): UserType[] => state.userPage.users;

export const getIsLoadingUsersStatus = (state: State): boolean =>
  state.userPage.isLoadingUsers;

export const getUserPageError = (state: State): string => state.userPage.error;

export const getUserTotal = (state: State) => state.userPage.total;