export enum TypePersonOrder {
  entity = "Юр. лицо",
  individual = "Физ. лицо",
}

export enum PaymentTypeList {
  DeliveryAndOrder = "delivery-order",
  Free = "free",
}

export const PaymentTypeListText = {
  "delivery-order":
    "Наложный платеж - покупатель оплачивает доставку/товар при получении",
  free: "Бесплатная доставка для покупателя - покупатель ничего не оплачивает",
};

export enum OrderStatus {
  CREATED = "Создан",
  DELETED = "Удален",
  READY_FOR_SHIPMENT_IN_TRANSIT_CITY = "Выдан на отправку в г. транзите",
  RECEIVED_AT_SHIPMENT_WAREHOUSE = "Принят на склад отправителя",
  READY_TO_SHIP_AT_SENDING_OFFICE = "Выдан на отправку в г. отправителе",
  RETURNED_TO_SENDER_WAREHOUSE = "Возвращен на склад отправителя",
  PASSED_TO_CARRIER_AT_SENDING_OFFICE = "Сдан перевозчику в г. отправителе",
  SEND_TO_TRANSIT_OFFICE = "Отправлен в г. транзит",
  MET_AT_TRANSIT_OFFICE = "Встречен в г. транзите",
  ACCEPTED_TO_OFFICE_TRANSIT_WAREHOUSE = "Принят на склад транзита",
  RETURNED_TO_TRANSIT_WAREHOUSE = "Возвращен на склад транзита",
  READY_TO_SHIP_IN_TRANSIT_OFFICE = "Выдан на отправку в г. транзите",
  PASSED_TO_CARRIER_AT_TRANSIT_OFFICE = "Сдан перевозчику в г. транзите",
  SEND_TO_SENDING_OFFICE = "Отправлен в г. отправитель",
  SEND_TO_RECIPIENT_OFFICE = "Отправлен в г. получатель",
  MET_AT_SENDING_OFFICE = "Встречен в г. отправителе",
  MET_AT_RECIPIENT_OFFICE = "Встречен в г. получателе",
  ACCEPTED_AT_DELIVERY_WAREHOUSE = "Принят на склад доставки",
  ACCEPTED_AT_WAREHOUSE_ON_DEMAND = "Принят на склад до востребования",
  ISSUED_FOR_DELIVERY = "Выдан на доставку",
  RETURNED_TO_DELIVERY_WAREHOUSE = "Возвращен на склад доставки",
  DELIVERED = "Вручен",
  NOT_DELIVERED = "Не вручен",
  ENTERED_TO_OFFICE_TRANSIT_WAREHOUSE = "Поступил в г. транзита",
  ENTERED_TO_DELIVERY_WAREHOUSE = "Поступил на склад доставки",
  ENTERED_TO_WAREHOUSE_ON_DEMAND = "Поступил на склад до востребования",
  IN_CUSTOMS_INTERNATIONAL = "Таможенное оформление в стране отправления",
  SHIPPED_TO_DESTINATION = "Отправлено в страну назначения",
  PASSED_TO_TRANSIT_CARRIER = "Передано транзитному перевозчику",
  IN_CUSTOMS_LOCAL = "Таможенное оформление в стране назначения",
  CUSTOMS_COMPLETE = "Таможенное оформление завершено",
  POSTOMAT_POSTED = "Заложен в постамат",
  POSTOMAT_SEIZED = "Изъят из постамата курьером",
  POSTOMAT_RECEIVED = "Изъят из постамата клиентом",
  READY_FOR_SHIPMENT_IN_SENDER_CITY = "Готов к отправке в городе отправителе",
  TAKEN_BY_TRANSPORTER_FROM_SENDER_CITY = "Сдан перевозчику в городе отправителе",
  SENT_TO_TRANSIT_CITY = "Отправлен в город-транзит",
  ACCEPTED_IN_TRANSIT_CITY = "Встречен в городе-транзите",
  ACCEPTED_AT_TRANSIT_WAREHOUSE = "Принят на склад транзита",
  TAKEN_BY_TRANSPORTER_FROM_TRANSIT_CITY = "Сдан перевозчику в городе-транзите",
  SENT_TO_RECIPIENT_CITY = "Отправлен в город-получатель",
  ACCEPTED_IN_RECIPIENT_CITY = "Встречен в городе- получателе",
  ACCEPTED_AT_PICK_UP_POINT = "Принят на склад до востребования",
  TAKEN_BY_COURIER = "Выдан на доставку",
  RETURNED_TO_RECIPIENT_CITY_WAREHOUSE = "Возвращен на склад доставки",
  true = "oплачен",
  false = "не oплачен",
  null = "не oплачен"
}
