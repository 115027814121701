import { createSlice } from '@reduxjs/toolkit';
import { UserLoginStatusType } from '../../types/user';

type userSliceType = {
  userLoginStatus: UserLoginStatusType;
  error: string;
  login: string | null;
  userId: string | null;
  role: string | null;
};

const initialState: userSliceType = {
  userLoginStatus: UserLoginStatusType.Unknown,
  error: '',
  login: null,
  userId: null,
  role: null,
};

// Slice для хранения данных текущего юзера
const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserAuthStatus: (state, action) => {
      state.userLoginStatus = action.payload;
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
    resetUserError: (state, action) => {
      state.error = action.payload;
    },
    setProfileDataToken(state, action) {
      state.login = action.payload.login;
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.userLoginStatus = UserLoginStatusType.Authorized;
    },
  },
});

export default userSlice.reducer;

export const {
  setUserAuthStatus,
  setUserError,
  resetUserError,
  setProfileDataToken,
} = userSlice.actions;
