import * as Yup from 'yup';
import {
  DimensionsType,
  PackageSettingType,
  PackagesSettingType,
} from '../../../types/order';
import { getValidationError } from '../../../utils/validation';
import { isEmpty } from 'lodash';

export const validationGeneralInfo = (
  packages: PackagesSettingType,
  dimensions: DimensionsType
) => {
  const errors: Record<string, any> = {};

  Object.values(packages).forEach((pack: PackageSettingType) => {
    const schema = Yup.object().shape({
      weight: Yup.number()
        .typeError('Введите корректное значение')
        .required('Это поле обязательное!')
        .positive('Значение должен быть больше 0')
        .test(
          'is-decimal',
          'Количество знаков после запятой должно быть не больше 2',
          (val: any) => {
            if (val !== undefined) {
              return /^\d+(\.\d{0,2})?$/.test(val);
            }
            return true;
          }
        )
        .test(
          'is-max',
          `Максимальное значение поля "Вес" не должно превышать ${dimensions?.weightMax}`,
          (value) => {
            if (
              dimensions?.weightMax &&
              Number(value) > dimensions?.weightMax
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          'is-min',
          `Минимальное значение поля "Вес" не должно превышать ${dimensions?.weightMin}`,
          (value) => {
            if (
              dimensions?.weightMin &&
              Number(value) < dimensions?.weightMin
            ) {
              return false;
            }
            return true;
          }
        ),
      height: Yup.number()
        .typeError('Введите корректное значение')
        .required('Это поле обязательное!')
        .integer('Значение должно быть целым числом!')
        .positive('Значение должен быть больше 0')
        .test(
          'is-max-heigth',
          `Минимальное значение поля "Высота" не должно превышать ${dimensions?.height}`,
          (value) => {
            if (dimensions?.height && Number(value) > dimensions?.height) {
              return false;
            }
            return true;
          }
        ),
      width: Yup.number()
        .typeError('Введите корректное значение')
        .required('Это поле обязательное!')
        .integer('Значение должно быть целым числом!')
        .positive('Значение должен быть больше 0')
        .test(
          'is-max-width',
          `Минимальное значение поля "Ширина" не должно превышать ${dimensions?.width}`,
          (value) => {
            if (dimensions?.width && Number(value) > dimensions?.width) {
              return false;
            }
            return true;
          }
        ),
      length: Yup.number()
        .typeError('Введите корректное значение')
        .required('Это поле обязательное!')
        .integer('Значение должно быть целым числом!')
        .positive('Значение должен быть больше 0')
        .test(
          'is-max-length',
          `Минимальное значение поля "Длинна" не должно превышать ${dimensions?.depth}`,
          (value) => {
            if (dimensions?.depth && Number(value) > dimensions?.depth) {
              return false;
            }
            return true;
          }
        ),
      document: Yup.string()
        .typeError('Введите корректное значение')
        .required('Это поле обязательное!'),
      packageNumber: Yup.number()
        .typeError('Введите корректное значение')
        .required('Это поле обязательное!')
        .positive('Значение должен быть больше 0'),
    });

    const result = getValidationError(schema, pack);

    if(isEmpty(result)){
      return;
    }

    errors[pack.id] = getValidationError(schema, pack);
  });

  if (Object.values(errors).length > 0) {
    return { errors: errors, isValid: false };
  }

  return { errors: null, isValid: true };
};
