import { useEffect, useState } from 'react';
import {
  GridRowParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import BasicModal from '../BasicModal/BasicModal';
import { UserType } from '../../types/user';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  getIsLoadingUsersStatus,
  getUserTotal,
  getUsers,
} from '../../redux/UserPageSlice/selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Table } from '../ui/Table/Table';
import { fetchUsersAction } from '../../redux/UserPageSlice/asyncActions';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', minWidth: 40, flex: 0.1 },
  { field: 'name', headerName: 'Поставщик', minWidth: 150, flex: 0.3 },
];

export const UserList = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(getUsers);
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const isLoading = useAppSelector(getIsLoadingUsersStatus);
  const matches = useMediaQuery('(min-width:450px)');
  const total = useAppSelector(getUserTotal);

  useEffect(() => {
    dispatch(fetchUsersAction({}));
  }, []);

  const fetchDataCallback = (page: number, limit: number) => {
    dispatch(fetchUsersAction({ page, limit }));
  };

  const handleProductListItemClick = (params: GridRowParams): void => {
    setOpen(true);
    setCurrentUser(params.row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          height: '600px',
          width: '100%',
          paddingTop: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Table
          columns={columns}
          fetchDataCallback={fetchDataCallback}
          totalCount={total}
          handleProductListItemClick={handleProductListItemClick}
          items={users}
          idField='userId'
          isLoading={isLoading}
          getRowHeight={matches ? () => undefined : () => 'auto'}
        />
      </div>
      {currentUser && (
        <BasicModal modalState={open} onClose={handleClose}>
          <>
            <Typography variant='h5' sx={{ mb: 2 }}>
              <div>Пользователь: {currentUser.id}</div>
              <div>Логин: {currentUser.login}</div>
              <div>Пароль: {currentUser.password}</div>
            </Typography>
          </>
        </BasicModal>
      )}
    </>
  );
};
