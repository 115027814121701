import { PaymentTypeList } from '../../../consts/order';
import { TariffType } from '../../../types/order';

export const validationTariff = (
  paymentType: PaymentTypeList,
  priceCOD: number | '',
  tariff: TariffType | null
) => {
  const errors: Record<string, string> = {};
  let isValid = true;

  if (paymentType === PaymentTypeList.DeliveryAndOrder && !priceCOD) {
    isValid = false;
  }

  if (!tariff) {
    isValid = false;
    errors['tariff'] = 'Пожалуйста, выберите тариф!';
  }

  return { isValid, errors };
};
