import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Order } from '../../types/order';

type OrderListSliceType = {
  changedOrder: Order | null;
  orders: Order[];
  isLoadingOrders: boolean;
  error: string;
  isOrderPayment: boolean;
  totalOrders: number;
};

const initialState: OrderListSliceType = {
  changedOrder: null,
  orders: [],
  isLoadingOrders: false,
  error: '',
  isOrderPayment: false,
  totalOrders: 0,
};

const orderPageSlice = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    setTotalOrders: (state, action: PayloadAction<number>) => {
      state.totalOrders = action.payload;
    },
    addNewOrder: (state, action) => {
      state.orders = [action.payload, ...state.orders];
    },
    setChangedOrder: (state, action) => {
      state.changedOrder = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setIsLoadingOrdersStatus: (state, action) => {
      state.isLoadingOrders = action.payload;
    },
    setOrderError: (state, action) => {
      state.error = action.payload;
    },
    resetOrderError: (state, action) => {
      state.error = action.payload;
    },
    updateOrderStatus: (state, action) => {
      const targetElemIndex = state.orders.indexOf(
        state.orders.find(
          (el: any) => el.cdekOrderId === action.payload.cdekOrderId
        ) as Order
      );
      state.orders[targetElemIndex].status = action.payload.status;
    },
    updateOrderCdekNumber: (state, action) => {
      const targetElemIndex = state.orders.indexOf(
        state.orders.find(
          (el: any) => el.cdekOrderId === action.payload.cdekOrderId
        ) as Order
      );
      state.orders[targetElemIndex].cdekNumber = action.payload.cdekNumber;
    },
    updateOrderPrintForm: (state, action) => {
      const targetElemIndex = state.orders.indexOf(
        state.orders.find(
          (el: any) => el.cdekOrderId === action.payload.cdekOrderId
        ) as Order
      );
      state.orders[targetElemIndex].printFormDocumentCreated = true;
      state.orders[targetElemIndex].printFormId = action.payload.printFormId;

      if (state.changedOrder) {
        state.changedOrder.printFormDocumentCreated = true;
        state.changedOrder.printFormId = action.payload.printFormId;
      }
    },
    setPrintFormDocumentId: (state, action) => {
      const { cdekOrderId, printFormId } = action.payload;

      const targetElemIndex = state.orders.indexOf(
        state.orders.find(
          (el: Order) => el.cdekOrderId === cdekOrderId
        ) as Order
      );

      state.orders[targetElemIndex].printFormId = printFormId;
    },
    setOrderIsPayed: (state, action) => {
      state.orders[action.payload].isOrderPayed = true;
    },
    setBarcodeId: (state, action) => {
      const { cdekOrderId, barcodeId } = action.payload;
      const targetElemIndex = state.orders.indexOf(
        state.orders.find(
          (el: any) => el.cdekOrderId === cdekOrderId
        ) as Order
      );
      state.orders[targetElemIndex].barcodeId = barcodeId;
    },
    updateBarcode: (state, action) => {
      const targetElemIndex = state.orders.indexOf(
        state.orders.find(
          (el: any) => el.cdekOrderId === action.payload.cdekOrderId
        ) as Order
      );
      state.orders[targetElemIndex].barcodeStatus = true;
      state.orders[targetElemIndex].barcodeId = action.payload.barcodeId;

      if (state.changedOrder) {
        state.changedOrder.barcodeStatus = true;
        state.changedOrder.barcodeId = action.payload.barcodeId;
      }
    },
    replaceOrder: (state, action) => {
      const { cdekOrderId, order } = action.payload;
      const targetElemIndex = state.orders.indexOf(
        state.orders.find((el: any) => el.cdekOrderId === cdekOrderId) as Order
      );
      state.orders[targetElemIndex] = order;
    },
  },
});

export const {
  setChangedOrder,
  setOrders,
  setIsLoadingOrdersStatus,
  setOrderError,
  resetOrderError,
  addNewOrder,
  updateOrderStatus,
  updateOrderCdekNumber,
  updateOrderPrintForm,
  setPrintFormDocumentId,
  setOrderIsPayed,
  setBarcodeId,
  updateBarcode,
  replaceOrder,
  setTotalOrders
} = orderPageSlice.actions;
export default orderPageSlice.reducer;
