import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import navConfig from "./config";
import Scrollbar from "../scrollbar/Scrollbar";
import Logo from "../Logo/Logo";
import useResponsive from "../../../hooks/useResponsive";
import NavSection from "../NavSection/NavSection";
import LogoutButton from "../logoutButton/LogoutButton";
import { SupportButton } from "../SupportButton/SupportButton";

const NAV_WIDTH = 280;

type NavPanelProps = {
  openNav: boolean;
  onCloseNav: () => void;
};

const NavPanel = ({ openNav, onCloseNav }: NavPanelProps) => {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg", "sm");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            px: 2.5,
            py: 3,
            display: "inline-flex",
            height: "92px",
            boxSizing: "border-box",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Box>

        <NavSection linkList={navConfig} />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
      <SupportButton />
      <LogoutButton />
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

export default NavPanel;
