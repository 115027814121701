import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseButtonModal from '../CloseIconModal/CloseIconModal';

const style = {
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
};

interface IModal {
  onClose: () => void;
  modalState: boolean;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

export default function BasicModal(props: IModal) {
  const { onClose, modalState, children, className } = props;
  const closeModal = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        className={className}
        open={modalState}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          overflow: 'auto',
          height: '100%',
          display: 'block',
          margin: 'auto',
          maxHeight: '90%',
          minWidth: '300px',
          maxWidth: '70%',
          borderRadius: 2,
        }}
      >
        <Box sx={style}>
          <CloseButtonModal onClose={closeModal} />
          {children}
        </Box>
      </Modal>
    </div>
  );
}
