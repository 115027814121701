import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { PaymentTypeList } from "../../../consts/order";
import CreateNewOrderTotalPrice from "./CreateNewOrderTotalPrice/CreateNewOrderTotalPrice";

type CreateNewOrderPaymentProps = {
  paymentType: PaymentTypeList;
  setPaymentType: (value: PaymentTypeList) => void;
  priceCOD: "" | number;
  setPriceCOD: (value: number | "") => void;
};

const CreateNewOrderPayment = ({
  paymentType,
  setPaymentType,
  priceCOD,
  setPriceCOD,
}: CreateNewOrderPaymentProps) => {
  return (
    <FormControl sx={{ marginBottom: "20px", maxWidth: '600px' }}>
      <FormLabel id="payment-change-group">Тип оплаты:</FormLabel>
      <RadioGroup
        row
        aria-labelledby="payment-change-group"
        name="payment-change-group"
        value={paymentType}
        onChange={(evt) => setPaymentType(evt.target.value as PaymentTypeList)}
        sx={{ marginBottom: "10px" }}
      >
        <FormControlLabel
          value={PaymentTypeList.DeliveryAndOrder}
          control={<Radio />}
          label="Наложный платеж - покупатель оплачивает доставку/товар при получении"
        />
        <FormControlLabel
          value={PaymentTypeList.Free}
          control={<Radio />}
          label="Бесплатная доставка для покупателя - покупатель ничего не оплачивает"
        />
      </RadioGroup>

      {paymentType === PaymentTypeList.DeliveryAndOrder && (
        <CreateNewOrderTotalPrice
          priceCOD={priceCOD}
          setPriceCOD={setPriceCOD}
          isError={true}
        />
      )}
    </FormControl>
  );
};

export default CreateNewOrderPayment;
