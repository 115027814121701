import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TariffType } from "../../../../types/order";
import { PaymentTypeList, PaymentTypeListText } from "../../../../consts/order";

type TariffInfoAccordionProps = {
  tariff: TariffType | null;
  paymentType: PaymentTypeList;
  priceCOD: number | "";
  setActiveStep: (value: number) => void;
  expanded: string | boolean;
  handleChangeAccordion: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const TariffInfoAccordion = ({
  tariff,
  paymentType,
  priceCOD,
  handleChangeAccordion,
  expanded,
  setActiveStep,
}: TariffInfoAccordionProps) => {
  return (
    <>
      <Accordion
        expanded={expanded === "tariff-info"}
        onChange={handleChangeAccordion("tariff-info")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="tariff-info"
          id="tariff-info"
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography
              className="create-new-order__finish-title"
              variant="h5"
              component="h5"
            >
              Тариф и оплата
            </Typography>

            <Button onClick={() => setActiveStep(1)}>Изменить</Button>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            className="create-new-order__finish"
            spacing={2}
            flexDirection="column"
            component="ul"
            sx={{ padding: 0, margin: 0, listStyle: "none" }}
          >
            {tariff && (
              <Stack
                className="create-new-order__finish"
                spacing={2}
                flexDirection="column"
                component="ul"
                sx={{ padding: 0, margin: 0, listStyle: "none" }}
              >
                <li>
                  <b>Тип тарифа: </b>
                  <span>{tariff.tariff_name}</span>
                </li>
                <li>
                  <b>Цена доставки: </b>
                  <span>{tariff.delivery_sum}</span>
                </li>
                <li>
                  <b>Срок доставки: </b>
                  <span>
                    {tariff.period_min} - {tariff.period_max}
                  </span>
                </li>
              </Stack>
            )}
            {paymentType && (
              <li>
                <b>Тип оплаты: </b>
                <span>{PaymentTypeListText[paymentType]}</span>
              </li>
            )}
            {priceCOD && (
              <li>
                <b>Сумма наложенного платежа: </b>
                <span>{priceCOD}</span>
              </li>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TariffInfoAccordion;
