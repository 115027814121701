import { connect } from "react-redux";
import { State } from "../../types/redux";
import { FC } from "react";
import { NavLinkType } from "../../types/navigation";
import { ItemDetailOrderListProps } from "../DetailOrderModal/ItemDetailWithAdminAccess";

type NavItemProps = {
  link: NavLinkType;
};

let mapStateToProps = (state: State) => ({
  role: state.user.role,
});

export const WithAdminAccess = (
  Component: FC<NavItemProps> | FC<ItemDetailOrderListProps>,
  isReturn: boolean
) => {
  const RedirectComponent = (props: any) => {
    if (props.role !== "ADMIN") {
      return isReturn ? (
        <span className="admin-access-text">
          Доступ только для пользователей admin
        </span>
      ) : null;
    }
    return <Component {...props} />;
  };

  return connect(mapStateToProps)(RedirectComponent);
};
