import axios from 'axios';
import { propertyOf } from 'lodash';

import { CreatingUserType } from './../types/user';
import { NewReport, Report } from '../types/report';
import accessTokenController from './accessTokenController';
import { ApiRoutes, UnauthorizedUserResponse } from '../consts/api';
import {
  OrderType,
  Order,
  BarcodeDataTypes,
  IUpdateOrderData,
} from '../types/order';
import { IAutocomplitingAddress, TariffOrderType } from './../types/order';

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

const GET_ADRESS_URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
const TIMEOUT_SERVER = 50000;

export const createApi = () => {
  const api = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT_SERVER,
  });

  api.interceptors.request.use(
    function (request) {
      const ACCESS_TOKEN = accessTokenController.checkAuth();

      if (ACCESS_TOKEN) {
        request.headers = { Authorization: 'Bearer ' + ACCESS_TOKEN };
      }

      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // подключаем перехватчик к нашему axios instance
  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error.response.status === UnauthorizedUserResponse.Status &&
        error.response.statusText === UnauthorizedUserResponse.Text
      ) {
        accessTokenController.logout();
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export const createApiGetAdresses = () => {
  const api = axios.create({
    baseURL: GET_ADRESS_URL,
    headers: {
      Accept: 'application/json',
      Authorization: process.env.REACT_APP_AUTHORIZATION_GET_ADRESS,
    },
  });

  return api;
};

export const getAccessToken = async (login: string, password: string) => {
  const api = createApi();

  const { data } = await api.post(`${BASE_URL}${ApiRoutes.SignIn}`, {
    login,
    password,
  });
  return data;
};

export const getUserList = async () => {
  const api = createApi();

  const { data } = await api.get(`${BASE_URL}${ApiRoutes.User}`);
  return data;
};

export const createNewOrder = async (newOrder: OrderType) => {
  const api = createApi();

  const result = await api.post(
    `${BASE_URL}${ApiRoutes.CreateOrder}`,
    newOrder
  );
  return result;
};

export const createNewUser = async (newUser: CreatingUserType) => {
  const api = createApi();
  const result = await api.post(`${BASE_URL}${ApiRoutes.User}`, newUser);

  return result;
};

export const printOrderForm = async (cdekOrderId: string): Promise<string> => {
  const api = createApi();

  const result = await api.post(`${BASE_URL}${ApiRoutes.PrintOrderForm}`, {
    cdekOrderId,
  });

  return propertyOf(result)(['data', 'entity', 'uuid']);
};

export const getPrintFormPdf = async (printFormId: string) => {
  const api = createApi();

  const result = await api.post(
    `${BASE_URL}${ApiRoutes.GetPrintFormPdf}`,
    {
      printFormId,
    },
    {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }
  );

  return result;
};

export const getPrintFormPdfWebApp = async (
  printFormId: string,
  cdekNumber: string,
  chatId: string
) => {
  const api = createApi();

  const result = await api.post(
    `${BASE_URL}${ApiRoutes.GetPrintFormPdfWebApp}`,
    {
      printFormId,
      cdekNumber,
      chatId,
    }
  );

  return result;
};

export const updateOrderPaymentStatus = async (id: string) => {
  const api = createApi();

  const result = await api.post(
    `${BASE_URL}${ApiRoutes.UpdateOrderPaymentStatus}`,
    {
      id,
      isOrderPayed: true,
    }
  );

  return result;
};

export const updateOrder = async (orderData: IUpdateOrderData) => {
  const api = createApi();

  const { data } = await api.patch(
    `${BASE_URL}${ApiRoutes.UpdateOrder}`,
    orderData
  );

  return data;
};

export const fetchTariffList = async (orderData: TariffOrderType) => {
  const api = createApi();

  const result = await api.post(
    `${BASE_URL}${ApiRoutes.getTariffs}`,
    orderData
  );
  return result;
};

export const fetchCitiesSdekCode = async ({
  locationToFiasCode,
  locationFromFiasCode,
}: {
  locationToFiasCode: string;
  locationFromFiasCode: string;
}): Promise<{ cityCodeFrom: number; cityCodeTo: number }> => {
  const api = createApi();

  const { data } = await api.get(`${BASE_URL}${ApiRoutes.getCitiesSdekCode}`, {
    params: {
      locationToFiasCode,
      locationFromFiasCode,
    },
  });
  return data;
};

export const getOfficeListBySdekCityCode = async (
  sdekCityCode: number | null
) => {
  const api = createApi();

  if (!sdekCityCode) {
    return;
  }

  const result = await api.get(
    `${BASE_URL}${ApiRoutes.GetOfficeList}?code=${sdekCityCode}`
  );

  return result.data;
};

export const createNewReport = async (newReport: NewReport) => {
  const api = createApi();
  const { data } = await api.post(
    `${BASE_URL}${ApiRoutes.CreateReport}`,
    newReport
  );
  return data as { report: Report; orders: Order[] };
};

export const createBarcode = async (barcodeData: BarcodeDataTypes) => {
  const api = createApi();
  const { data } = await api.post(
    `${BASE_URL}${ApiRoutes.Barcode}`,
    barcodeData
  );

  return propertyOf(data)(['entity', 'uuid']);
};

export const getBarcode = async (barcodeId: string) => {
  const api = createApi();

  const result = await api.post(
    `${BASE_URL}${ApiRoutes.PrintBarcode}`,
    {
      barcodeId,
    },
    {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }
  );

  return result;
};

export const getBarcodeWebApp = async (
  barcodeId: string,
  cdekNumber: string,
  chatId: string
) => {
  const api = createApi();

  const result = await api.post(`${BASE_URL}${ApiRoutes.PrintBarcodeWebApp}`, {
    barcodeId,
    cdekNumber,
    chatId,
  });

  return result;
};

export const getOrdersReport = async (reportId: string) => {
  const api = createApi();
  const { data } = await api.get(
    `${BASE_URL}${ApiRoutes.GetReports}/${reportId}`,
    {
      params: { id: reportId },
    }
  );
  return data as { report: Report; orders: Order[] };
};

// Limit of 10,000 requests per day
export const getAdress = async (value: string, amountAddress: number) => {
  const api = createApiGetAdresses();
  const { data } = await api.post('/', {
    query: value,
    count: amountAddress,
    from_bound: { value: 'city' },
    to_bound: { value: 'settlement' },
  });

  return data.suggestions.map((address: IAutocomplitingAddress) => ({
    value: address.value,
    fiasCode: propertyOf(address)(['data', 'fias_id']),
    lat: propertyOf(address)(['data', 'geo_lat']),
    lon: propertyOf(address)(['data', 'geo_lon']),
    postalCode: propertyOf(address)(['data', 'postal_code']),
  }));
};

export const deleteOrder = async (uuid: string) => {
  const api = createApi();

  const { data } = await api.delete(ApiRoutes.DeleteOrder, {
    params: {
      uuid,
    },
  });

  return data;
};
