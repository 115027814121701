import { useEffect, useState } from "react";
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  getUserAuthStatus,
  getUserError,
} from "../../redux/UserSlice/selector";
import { UserLoginStatusType } from "../../types/user";
import { loginUserAction } from "../../redux/UserSlice/asyncActions";
import { FormHelperText } from "@mui/material";
import { useFormik, FormikProps } from "formik";
import { validationLoginForm } from "./validateLoginForm";

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const userAuthStatus = useAppSelector(getUserAuthStatus);
  const loginError = useAppSelector(getUserError);
  const [loginErrorStatus, setLoginErrorStatus] = useState<number | null>(null);

  interface ValuesLoginForm {
    login: string;
    password: string;
  }

  const formikLogin: FormikProps<ValuesLoginForm> = useFormik<ValuesLoginForm>({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: validationLoginForm,
    onSubmit: (values) => {
      dispatch(
        loginUserAction({ login: values.login, password: values.password })
      );
    },
  });

  useEffect(() => {
    if (loginError) {
      setLoginErrorStatus(Number(loginError.split(" ")[0]));
    }
  }, [loginError]);

  if (userAuthStatus === UserLoginStatusType.Authorized) {
    return <Navigate to="/" />;
  }

  return (
    <form onSubmit={formikLogin.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          name="login"
          label="Логин"
          onChange={formikLogin.handleChange}
          error={formikLogin.touched.login && Boolean(formikLogin.errors.login)}
          helperText={formikLogin.touched.login && formikLogin.errors.login}
        />

        <TextField
          name="password"
          label="Пароль"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={formikLogin.handleChange}
          error={
            formikLogin.touched.password && Boolean(formikLogin.errors.password)
          }
          helperText={
            formikLogin.touched.password && formikLogin.errors.password
          }
        />
        {loginErrorStatus === 401 && (
          <FormHelperText error>Неверный логин или пароль</FormHelperText>
        )}
      </Stack>

      <LoadingButton
        sx={{
          mt: "25px",
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Войти
      </LoadingButton>
    </form>
  );
}
