import { Stack, Typography } from "@mui/material";
import CreateNewUser from "../../CreateNewUser/CreateNewUser";
import { WithAdminAccess } from "../../hoc/WithAdminAccess";
import { UserList } from "../../UserList/UserList";

const UsersPage = () => {  
  return (
    <section>
      <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
        <Typography variant="h4">Пользователи</Typography>
        <CreateNewUser />
      </Stack>
      <UserList />
    </section>
  );
};

export default WithAdminAccess(UsersPage, true);
