import { Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import OrdersPage from "../pages/OrdersPage/OrdersPage";
import UsersPage from "../pages/UsersPage/UsersPage";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import accessTokenController from "../../services/accessTokenController";
import ReportsPage from "../pages/ReportsPage/ReportsPage";
import {
  setProfileDataToken,
  setUserAuthStatus,
} from "../../redux/UserSlice/userSlice";
import { getUserAuthStatus } from "../../redux/UserSlice/selector";
import { UserLoginStatusType } from "../../types/user";
import { TailSpin } from "react-loader-spinner";
import ReportPage from "../pages/ReportPage/ReportPage";
import { useTelegram } from "../../hooks/useTelegram";
import { useActiveElement } from "../../hooks/useActiveElement";
import { getDeviceType } from "../../utils";

const App = () => {
  const dispatch = useAppDispatch();
  const userLoginStatus = useAppSelector(getUserAuthStatus);
  const { user, tg } = useTelegram();
  const focusedElement = useActiveElement() as HTMLElement;
  const deviceType = getDeviceType();

  tg.MainButton.text = "Скрыть клавиатуру";
  tg.MainButton.onClick(() => {
    if (focusedElement) {
      focusedElement.blur();
    }
  });

  useEffect(() => {
    if (
      focusedElement &&
      focusedElement.tagName === "INPUT" &&
      user &&
      deviceType !== "desktop" &&
      focusedElement.getAttribute("type") !== "radio"
    ) {
      tg.MainButton.show();
      return;
    }
    tg.MainButton.hide();
  }, [focusedElement]);

  useEffect(() => {
    tg.expand();
    tg.ready();
  }, []);

  useEffect(() => {
    const token = accessTokenController.getToken();

    if (token !== null) {
      const tokenData = accessTokenController.decodeToken(token);
      dispatch(setProfileDataToken(tokenData));
    } else {
      dispatch(setUserAuthStatus(UserLoginStatusType.Unauthorized));
    }
  }, []);

  return userLoginStatus === UserLoginStatusType.Unknown ? (
    <TailSpin
      color="#2274a5"
      wrapperStyle={{ margin: "300px", justifyContent: "center" }}
    />
  ) : (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<DashboardLayout />}>
        <Route path="/" element={<OrdersPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/reports" element={<ReportsPage />} />
      </Route>
      <Route path="/reports/:reportId" element={<ReportPage />} />
      <Route path="*" element={<div>Error</div>} />
    </Routes>
  );
};

export default App;
