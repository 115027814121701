import { Container, Typography } from "@mui/material";
import LoginForm from "../../LoginForm/LoginForm";

export default function LoginPage() {
  return (
    <>
      <Container sx={{
        width: '300px',
        mt: '200px',
      }}>
        <Typography variant="h4" gutterBottom>
          Sign in to Sdek
        </Typography>

        <LoginForm />
      </Container>
    </>
  );
}
