import { useEffect } from 'react';
import {
  GridRowParams,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import moment from 'moment';
import {
  getIsLoadingReportsStatus,
  getReports,
  getTotalReports,
} from '../../redux/ReportSlice/selector';
import { fetchReportsAction } from '../../redux/ReportSlice/asyncActions';
import redirectToPage from '../../services/redirectToPage';
import { useTelegram } from '../../hooks/useTelegram';
import { useNavigate } from 'react-router';
import { Table } from '../ui/Table/Table';
import useMediaQuery from '@mui/material/useMediaQuery';

const columns: GridColDef[] = [
  {
    field: 'reportType',
    headerName: 'Тип отчета',
    minWidth: 60,
    flex: 0.2,
    valueGetter: (params: GridValueGetterParams) => {
      const settings = JSON.parse(params.row.settings);
      return settings.reportType;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Дата',
    minWidth: 150,
    flex: 0.2,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      const date = moment(params.value);
      return date.format('DD.MM.YYYY HH:mm');
    },
  },
  { field: 'reportId', headerName: 'id отчета', minWidth: 150, flex: 0.3 },
  {
    field: 'reportUserName',
    headerName: 'Поставщик',
    minWidth: 150,
    flex: 0.3,
  },
];

export const ReportsList = () => {
  const dispatch = useAppDispatch();
  const reports = useAppSelector(getReports);
  const isLoading = useAppSelector(getIsLoadingReportsStatus);
  const { user } = useTelegram();
  const navigate = useNavigate();
  const total = useAppSelector(getTotalReports);
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    dispatch(fetchReportsAction({}));
  }, []);

  const fetchDataCallback = (page: number, limit: number) => {
    dispatch(fetchReportsAction({ page, limit }));
  };

  const handleReportListItemClick = async (params: GridRowParams) => {
    if (user) {
      navigate(`/reports/${params.row.reportId}`);
      return;
    }
    redirectToPage(`reports/${params.row.reportId}`, '_blanc', 'noreferrer');
  };

  return (
    <div
      style={{
        height: '600px',
        width: '100%',
        paddingTop: '20px',
        boxSizing: 'border-box',
      }}
    >
      <Table
        columns={columns}
        fetchDataCallback={fetchDataCallback}
        totalCount={total}
        handleProductListItemClick={handleReportListItemClick}
        items={reports}
        idField='reportId'
        isLoading={isLoading}
        getRowHeight={matches ? () => undefined : () => 'auto'}
      />
    </div>
  );
};
