import Link from '@mui/material/Link';
import cls from './SupportButton.module.scss';
import TelegramIcon from '@mui/icons-material/Telegram';

export const SupportButton = () => {
  return (
    <div className={cls.SupportButton}>
      <TelegramIcon className={cls.icon}/>
      <Link href='https://t.me/LinkWiSupportBot'>Служба Поддержки</Link>
    </div>
  );
};
