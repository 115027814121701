import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { State } from "../../types/redux";
import { FC } from "react";
import { UserLoginStatusType } from "../../types/user";

let mapStateToProps = (state: State) => ({
  authStatus: state.user.userLoginStatus,
});

export const WithAuthRedirect = (Component: FC) => {
  const RedirectComponent = (props: any) => {
    if (props.authStatus !== UserLoginStatusType.Authorized) {
      return <Navigate to={"/login"} />;
    }

    return <Component {...props} />;
  };

  return connect(mapStateToProps)(RedirectComponent);
};
