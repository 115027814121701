import { useEffect } from 'react';
import { useState } from 'react';

export const useActiveElement = () => {
    const [active, setActive] = useState(document.activeElement);
    
    const handleFocusIn = (e: Event) => {
      setActive(document.activeElement);
    }
    
    useEffect(() => {
      document.addEventListener('focusin', handleFocusIn)
      return () => {
        document.removeEventListener('focusin', handleFocusIn)
    };
    }, [])
    
    return active;
  }