import { NavLink } from "react-router-dom";
import { FC } from "react";
import { NavLinkType } from "../../../types/navigation";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

type NavItemProps = {
  link: NavLinkType;
};

const NavItem: FC<NavItemProps> = ({ link }) => {
  const { title, path, icon } = link;

  return (
    <ListItemButton
      disableGutters
      component={NavLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
          position: "relative",
          textTransform: "capitalize",
          borderRadius: "5px",
        },
      }}
    >
      <ListItemIcon className="nav-item-icon">{icon}</ListItemIcon>

      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default NavItem;
