import { Grid, TextField, Button, Typography } from '@mui/material';
import _ from 'lodash';
import { PackageSettingType } from '../../../types/order';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getPackages } from '../../../redux/CreateOrderSlice/selector';
import {
  setPackages,
  updatePackageField,
} from '../../../redux/CreateOrderSlice/createOrderSlice';
import { getDefaultPackage } from '../../../redux/CreateOrderSlice/lib/order';
import { memo } from 'react';

type CreateNewOrderFormGeneralInfoProps = {
  errors: Record<string, any>;
};

const CreateNewOrderFormGeneralInfo = ({
  errors,
}: CreateNewOrderFormGeneralInfoProps) => {
  const packages = useAppSelector(getPackages);
  const dispatch = useAppDispatch();

  const handleAddPackage = () => {
    const currentPackagesCount = Object.keys(packages).length;
    
    const newPackage = getDefaultPackage(currentPackagesCount);

    dispatch(
      setPackages({
        ...packages,
        ...newPackage,
      })
    );
  };

  const changePackagesField = (
    packageId: string,
    fieldName: keyof PackageSettingType,
    value: string
  ) => {
    dispatch(updatePackageField({ packageId, fieldName, value }));
  };

  const handleDeletePackage = (id: string) => {
    const newPackages = cloneDeep(packages);
    delete newPackages[id];

    dispatch(
      setPackages({
        ...newPackages,
      })
    );
  };

  return (
    <Grid container spacing={3} flexDirection='column' sx={{
      maxWidth: '540px'
    }}>
      {Object.values(packages).map((packageSetting, index) => (
        <Grid item container justifyContent='space-between' spacing={3} key={packageSetting.id}>
          <Grid item>
            <Typography
              variant='body1'
              sx={{
                color: 'text.secondary',
                fontWeight: 'bold',
              }}
            >
              Параметры места {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Вес (в кг)'
              fullWidth
              type='number'
              inputProps={{
                onWheel: (evt) => evt.currentTarget.blur(),
              }}
              name={`packages.${packageSetting.id}.weight`}
              onChange={(evt) =>
                changePackagesField(
                  packageSetting.id,
                  'weight',
                  evt.target.value
                )
              }
              value={packages[packageSetting.id].weight}
              required
              error={Boolean(errors[packageSetting.id]?.weight)}
              helperText={errors[packageSetting.id]?.weight}
            />
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label='Длинна (в см)'
                name={`packages.${packageSetting.id}.length`}
                type='number'
                onChange={(evt) =>
                  changePackagesField(
                    packageSetting.id,
                    'length',
                    evt.target.value
                  )
                }
                value={packages[packageSetting.id].length}
                required
                inputProps={{
                  onWheel: (evt) => evt.currentTarget.blur(),
                }}
                error={Boolean(errors[packageSetting.id]?.length)}
                helperText={errors[packageSetting.id]?.length}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label='Ширина (в см)'
                name={`packages.${packageSetting.id}.width`}
                type='number'
                onChange={(evt) =>
                  changePackagesField(
                    packageSetting.id,
                    'width',
                    evt.target.value
                  )
                }
                value={packages[packageSetting.id].width}
                required
                inputProps={{
                  onWheel: (evt) => evt.currentTarget.blur(),
                }}
                error={Boolean(errors[packageSetting.id]?.width)}
                helperText={errors[packageSetting?.id]?.width}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label='Высота (в см)'
                name={`packages.${packageSetting.id}.height`}
                type='number'
                onChange={(evt) =>
                  changePackagesField(
                    packageSetting.id,
                    'height',
                    evt.target.value
                  )
                }
                value={packages[packageSetting.id].height}
                required
                inputProps={{
                  onWheel: (evt) => evt.currentTarget.blur(),
                }}
                error={Boolean(errors[packageSetting.id]?.height)}
                helperText={errors[packageSetting.id]?.height}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={3}>
            <Grid item xs={12} sm={8}>
              <TextField
                label='Описание груза'
                fullWidth
                multiline
                name={`packages.${packageSetting.id}.document`}
                onChange={(evt) =>
                  changePackagesField(
                    packageSetting.id,
                    'document',
                    evt.target.value
                  )
                }
                value={packages[packageSetting.id].document}
                required
                maxRows={10}
                error={Boolean(errors[packageSetting.id]?.document)}
                helperText={errors[packageSetting.id]?.document}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Номер упаковки'
                fullWidth
                name={`packages.${packageSetting.id}.packageNumber`}
                required
                type='number'
                onChange={(evt) =>
                  changePackagesField(
                    packageSetting.id,
                    'packageNumber',
                    evt.target.value
                  )
                }
                value={packages[packageSetting.id].packageNumber}
                inputProps={{
                  onWheel: (evt) => evt.currentTarget.blur(),
                }}
                InputProps={{
                  readOnly: true,
                }}
                error={Boolean(errors[packageSetting.id]?.packageNumber)}
                helperText={errors[packageSetting.id]?.packageNumber}
              />
            </Grid>
          </Grid>
          {Object.values(packages).length !== 1 && (
            <Grid item ml={'auto'}>
              <Button
                variant='contained'
                color='error'
                sx={{ color: '#fff' }}
                onClick={() => handleDeletePackage(packageSetting.id)}
              >
                Удалить место
              </Button>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item>
        <Button variant='contained' onClick={handleAddPackage}>
          Добавить место
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(CreateNewOrderFormGeneralInfo);
