import { useState } from "react";
import NavItemButton from "../NavItemButton/NavItemButton";
import CreateNewOrderModal from "./CreateNewOrderModal/CreateNewOrderModal";

const CreateNewOrder = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <NavItemButton title="Новый заказ" onOpen={() => setOpen(true)} />
      <CreateNewOrderModal onClose={() => setOpen(false)} open={open} />
    </>
  );
};

export default CreateNewOrder;
