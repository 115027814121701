import { LoadingButton } from '@mui/lab';
import { memo, useState, useEffect } from 'react';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { BarcodeFormats } from './BarcodeFormats';
import { createBarcode, getBarcodeWebApp } from 'services/api';
import {
  setBarcodeId,
  updateBarcode,
} from 'redux/OrderPageSlice/orderPageSlice';
import { useAppDispatch } from 'hooks/redux';
import { useTelegram } from 'hooks/useTelegram';
import { socket } from 'services/socket';
import { handleBarcodeOpen } from 'utils/handleBarcodeOpen';
import { Order } from 'types/order';
import Button from '@mui/material/Button';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';

interface BarcodeProps {
  changedOrder: Order;
}

const Barcode = ({ changedOrder }: BarcodeProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [barcodeFormat, setBarcodeFormat] = useState(BarcodeFormats.A4);
  const { user } = useTelegram();
  const [url, setUrl] = useState('');
  const [barcodeFormatCreated, setBarcodeFormatCreated] =
    useState<null | BarcodeFormats>(null);

  useEffect(() => {
    socket.on('order:barcodeReady', ({ cdekOrderId, barcodeId }) => {
      dispatch(updateBarcode({ cdekOrderId, barcodeId }));

      handleBarcodeOpen(barcodeId).then((url) => {
        setUrl(url);
      });
    });

    return () => {
      socket.off('order:barcodeReady');
    };
  }, [dispatch]);

  const createBarcodeFunction = async () => {
    try {
      setIsLoading(true);

      const data = await createBarcode({
        cdekOrderId: changedOrder.cdekOrderId,
        format: barcodeFormat,
      });
      dispatch(
        setBarcodeId({
          cdekOrderId: changedOrder.cdekOrderId,
          barcodeId: data,
        })
      );
      setIsLoading(false);
      setBarcodeFormatCreated(barcodeFormat);
      setUrl('');

      showSuccessNotification('Заявка на создание ШК успешно отправлена!');
    } catch (error) {
      showErrorNotification('Заявка на создание ШК не отправлена!');
    }
  };

  const handleDownloadBarcode = async () => {
    if (!changedOrder?.barcodeId) {
      await createBarcodeFunction();
      return;
    }

    try {
      if (user) {
        await getBarcodeWebApp(
          changedOrder.barcodeId,
          changedOrder.cdekNumber,
          user.id
        );
        return;
      }

      if (barcodeFormatCreated !== barcodeFormat) {
        await createBarcodeFunction();
        return;
      }

      const url = await handleBarcodeOpen(changedOrder.barcodeId);
      setUrl(url);

      showSuccessNotification('Заявка на создание ШК успешно отправлена!');
    } catch (error: any) {
      if (error.response.status === 404) {
        await createBarcodeFunction();
      }
    }
  };

  return (
    <>
      <FormLabel id='barcode-types-group'>Формат ШК:</FormLabel>
      <RadioGroup
        row
        aria-labelledby='barcode-types-group'
        name='barcode-types-group'
        value={barcodeFormat}
        onChange={(evt) => setBarcodeFormat(evt.target.value as BarcodeFormats)}
        sx={{ marginBottom: '10px' }}
      >
        <FormControlLabel
          control={<Radio />}
          value={BarcodeFormats.A4}
          label={BarcodeFormats.A4}
        />
        <FormControlLabel
          control={<Radio />}
          value={BarcodeFormats.A5}
          label={BarcodeFormats.A5}
        />
        <FormControlLabel
          control={<Radio />}
          value={BarcodeFormats.A6}
          label={BarcodeFormats.A6}
        />
      </RadioGroup>

      <LoadingButton
        variant='outlined'
        color='info'
        onClick={handleDownloadBarcode}
        loading={isLoading}
      >
        Создать ШК
      </LoadingButton>
      {url && (
        <Button
          component='a'
          target='_blank'
          href={url}
          variant='text'
          color='success'
        >
          Скачать ШК
        </Button>
      )}
    </>
  );
};

export default memo(Barcode);
