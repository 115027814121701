import { MuiTelInput } from 'mui-tel-input';
import { memo } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface RecipientInfoProps {
  nameRecipient: string;
  setNameRecipient: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  errors: Record<string, string>;
}

export const RecipientInfo = memo(
  ({
    nameRecipient,
    setNameRecipient,
    setPhoneNumber,
    phoneNumber,
    errors,
  }: RecipientInfoProps) => {
    return (
      <>
        <Typography variant='h5' component='h4' sx={{ textAlign: 'center' }}>
          Получатель
        </Typography>

        <TextField
          label='ФИО получателя'
          fullWidth
          required
          value={nameRecipient}
          onChange={(evt) => setNameRecipient(evt.target.value)}
          error={Boolean(errors.nameRecipient)}
          helperText={errors.nameRecipient}
        />
        <MuiTelInput
          langOfCountryName='ru'
          defaultCountry='RU'
          onChange={(phone) => setPhoneNumber(phone)}
          value={phoneNumber}
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber}
          onlyCountries={['RU']}
          inputProps={{
            maxLength: 16
          }}
        />
      </>
    );
  }
);
