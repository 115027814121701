import BasicModal from 'components/BasicModal/BasicModal';
import { memo } from 'react';
import { Typography, Button, Box } from '@mui/material';
import cls from './DeleteOrderModal.module.scss';

interface DeleteOrderModalProps {
  open: boolean;
  onClose: () => void;
  onDeleteOrder: () => void;
  cdekNumber: string;
}

export const DeleteOrderModal = memo(
  ({ open, onClose, onDeleteOrder, cdekNumber }: DeleteOrderModalProps) => {
    return (
      <BasicModal
        modalState={open}
        onClose={onClose}
        className={cls.DeleteOrderModal}
      >
        <div>
          <Typography variant='h6' gutterBottom>
            Вы действительно хотите удалить заказ <b>№{cdekNumber}</b>?
          </Typography>

          <Box className={cls.buttons}>
            <Button onClick={onClose} color='info'>
              Отменить
            </Button>
            <Button onClick={onDeleteOrder} color='error'>
              Удалить
            </Button>
          </Box>
        </div>
      </BasicModal>
    );
  }
);
