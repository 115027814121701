import { v4 as uuidv4 } from 'uuid';
import { PackagesSettingType } from '../../../types/order';

export const getDefaultPackage = (currentPackageCount = 0): PackagesSettingType => {
  const id = uuidv4();
  
  return {
    [id]: {
      id,
      weight: '',
      height: '',
      width: '',
      length: '',
      document: 'Приложена опись',
      packageNumber: `${currentPackageCount + 1}`,
    },
  };
};
