import { matchIsValidTel } from 'mui-tel-input';
import * as Yup from 'yup';
import { getValidationError } from '../../../utils/validation';
import { IAutocomplitingAddress } from '../../../types/order';

interface BaseInfoI {
  locationFrom: IAutocomplitingAddress;
  locationTo: IAutocomplitingAddress;
  nameRecipient: string;
  phoneNumber: string;
}

const checkAddressRequired = (address: any) => {
  if (address?.value) {
    return true;
  }
  return false;
};

const checkPhone = (phoneNumber: string | undefined): boolean => {
  if (!phoneNumber || !matchIsValidTel(phoneNumber)) {
    return false;
  }

  return true;
};

const validationSchema = Yup.object({
  locationFrom: Yup.object()
    .nullable()
    .test('Address', 'Это поле обязательное!', checkAddressRequired),
  locationTo: Yup.object()
    .nullable()
    .test('Address', 'Это поле обязательное!', checkAddressRequired),
  nameRecipient: Yup.string().required('Это поле обязателное!'),
  phoneNumber: Yup.string()
    .required('Это поле обязателное!')
    .test('phone', 'Введите корректный номер телефона!', checkPhone),
});

export const validationBaseInfo = ({
  locationFrom,
  locationTo,
  nameRecipient,
  phoneNumber,
}: BaseInfoI) => {
  const loginValidateResult = getValidationError(validationSchema, {
    locationFrom,
    locationTo,
    nameRecipient,
    phoneNumber,
  });

  const errors = Object.values(loginValidateResult);

  if (errors.length > 0) {
    return { errors: loginValidateResult, isValid: false };
  }

  return { errors: null, isValid: true };
};
