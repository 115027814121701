import { useState } from "react";
import { Typography, Button, Alert } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import NavItemButton from "../NavItemButton/NavItemButton";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { createNewReport } from "../../services/api";
import { useFormik, FormikProps } from "formik";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import CloseButtonModal from "../CloseIconModal/CloseIconModal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { NewReport } from "../../types/report";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { reportSlice } from "../../redux/ReportSlice/reportSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import { getUsers } from "../../redux/UserPageSlice/selector";
import ConfirmDialog from "./ConfirmDialog";

const CreateNewReport = () => {
  const [open, setOpen] = useState<boolean>(false);
  const users = useAppSelector(getUsers);
  const [isShowDateInputs, setIsShowDateInputs] = useState<boolean>(false);
  const [modalSuccessIsOpen, setModalSuccessIsOpen] = useState(false);
  const [urlReport, setUrlReport] = useState("");
  const [isError, setIsError] = useState(false);

  const dispatch = useAppDispatch();

  const typesReport = ["Акт оказанных услуг"]; //ToDo: getting from server
  const { addReport } = reportSlice.actions;

  const closeModal = () => {
    formik.values.userName = "";
    formik.errors.userName = "";
    formik.values.reportType = "";
    formik.errors.reportType = "";
    formik.values.dateFrom = null;
    formik.errors.dateFrom = "";
    formik.values.dateTo = null;
    formik.errors.dateTo = "";
    setIsShowDateInputs(false);
    setOpen(false);
  };

  const validationSchema = yup.object().shape({
    userName: yup.string().required("Это поле обязательное!"),
    reportType: yup.string().required("Это поле обязательное!"),
    dateFrom: yup.object().nullable().required("Это поле обязательное!"),
    dateTo: yup.object().nullable().required("Это поле обязательное!"),
  });

  interface MyValues {
    userName: string;
    reportType: string;
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
  }

  const formik: FormikProps<MyValues> = useFormik<MyValues>({
    initialValues: {
      userName: "",
      reportType: "",
      dateFrom: null,
      dateTo: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values: MyValues) => {
      if (values.dateFrom && values.dateTo) {
        // commited for timestamp
        // const newReportData: NewReport = {
        //   userId: values.userId,
        //   dateFrom: values.dateFrom.unix(),
        //   dateTo: values.dateTo.unix(),
        //   reportType: values.reportType,
        // };

        const newReportData: NewReport = {
          userName: values.userName,
          dateFrom: values.dateFrom.toDate(),
          dateTo: values.dateTo.toDate(),
          reportType: values.reportType,
        };

        createNewReport(newReportData)
          .then((response) => {
            dispatch(addReport(response.report));
            closeModal();
            setUrlReport(response.report.reportId);
            setModalSuccessIsOpen(true);
          })
          .catch(() => {
            setIsError(true);
            setTimeout(() => setIsError(false), 3000);
          });
      }
    },
  });

  const onChangeReportType = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    formik.setFieldValue("reportType", event.target.value, true);
    switch (value) {
      case "Акт оказанных услуг":
        setIsShowDateInputs(true);
        break;
      default:
        setIsShowDateInputs(false);
    }
  };

  return (
    <>
      <NavItemButton
        title="Новый отчет"
        onOpen={() => setOpen(true)}
        open={open}
      />
      <Modal
        open={open}
        onClose={closeModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "relative",
            padding: "20px",
            background: "#fff",
            width: "700px",
            maxHeight: "90vh",
            overflow: "auto",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h5">Новый отчет</Typography>
          <FormControl fullWidth sx={{ mt: "20px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                flexDirection="column"
                spacing={1}
                justifyContent="start"
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  spacing={3}
                  sx={{ mb: "15px" }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="userName"
                      id="userName"
                      select
                      label="Поставщик"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.userName &&
                        Boolean(formik.errors.userName)
                      }
                      helperText={
                        formik.touched.userName && formik.errors.userName
                      }
                    >
                      {users.map((user) => (
                        <MenuItem key={user.name} value={user.name}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="reportType"
                      id="reportType"
                      select
                      label="Тип отчета"
                      value={formik.values.reportType}
                      onChange={onChangeReportType}
                      error={
                        formik.touched.reportType &&
                        Boolean(formik.errors.reportType)
                      }
                      helperText={
                        formik.touched.reportType && formik.errors.reportType
                      }
                    >
                      {typesReport.map((reportType) => (
                        <MenuItem key={reportType} value={reportType}>
                          {reportType}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {isShowDateInputs && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ru"
                  >
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      spacing={3}
                      sx={{ mb: "20px" }}
                    >
                      <Grid item xs={6}>
                        <DesktopDatePicker
                          label="От"
                          inputFormat="DD.MM.YYYY"
                          value={formik.values.dateFrom}
                          onChange={(value) => {
                            formik.setFieldValue("dateFrom", value, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              id="dateFrom"
                              name="dateFrom"
                              fullWidth
                              value={formik.values.dateFrom}
                              {...params}
                              helperText={
                                formik.touched.dateFrom &&
                                formik.errors.dateFrom
                              }
                              error={
                                formik.touched.dateFrom &&
                                Boolean(formik.errors.dateFrom)
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DesktopDatePicker
                          label="До"
                          inputFormat="DD.MM.YYYY"
                          value={formik.values.dateTo}
                          onChange={(value) => {
                            formik.setFieldValue("dateTo", value, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              name="dateTo"
                              fullWidth
                              value={formik.values.dateTo}
                              {...params}
                              error={
                                formik.touched.dateTo &&
                                Boolean(formik.errors.dateTo)
                              }
                              helperText={
                                formik.touched.dateTo && formik.errors.dateTo
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                )}
              </Grid>
              <Button type="submit" variant="contained" fullWidth>
                Создать
              </Button>
            </form>
          </FormControl>
          <CloseButtonModal onClose={closeModal} />
        </Box>
      </Modal>
      {modalSuccessIsOpen && (
        <ConfirmDialog
          open={modalSuccessIsOpen}
          setOpen={setModalSuccessIsOpen}
          reportId={urlReport}
          title={"Отчет успешно создан"}
        />
      )}
      {isError && (
        <Alert className="alert-request" variant="filled" severity="error">
          Ошибка при создании отчета!
        </Alert>
      )}
    </>
  );
};

export default CreateNewReport;
