import { useCallback, useEffect, useState } from 'react';
import {
  GridRowParams,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import DetailOrderModal from '../DetailOrderModal/DetailOrderModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  getIsLoadingOrdersStatus,
  getOrders,
  getTotalOrders,
} from '../../redux/OrderPageSlice/selector';
import { setChangedOrder } from '../../redux/OrderPageSlice/orderPageSlice';
import { OrderStatus } from '../../consts/order';
import useResponsive from '../../hooks/useResponsive';
import moment from 'moment';
import 'moment-timezone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditeOrderModal from '../EditeOrderModal/EditeOrderModal';
import { fetchOrdersAction } from '../../redux/OrderPageSlice/asyncActions';
import { Table } from '../ui/Table/Table';

const customCell = (params: GridRenderCellParams) => {
  const date = moment.tz(params.row.createdAt, 'Etc/GMT+3');
  const formattedDate = date.format('DD.MM.YYYY');
  return (
    <Box>
      <Typography color={'grey'}>{formattedDate}</Typography>
      <Typography>{params.row.cdekNumber}</Typography>
    </Box>
  );
};

const basicColumns: GridColDef[] = [
  {
    field: 'cdekNumber',
    headerName: 'Номер заказа',
    minWidth: 120,
    flex: 0.2,
    renderCell: (params) => customCell(params),
  },
  {
    field: 'status',
    headerName: 'статус заказа',
    minWidth: 120,
    flex: 0.2,
    valueGetter: (params) => {
      return (OrderStatus as any)[params.value];
    },
  },
  {
    field: 'locationFrom',
    headerName: 'Откуда',
    minWidth: 150,
    flex: 0.2,
    valueGetter: (params: GridValueGetterParams) => {
      try {
        const location = JSON.parse(params.row.locationFrom);
        return location.value;
      } catch (error) {
        return params.row.locationFrom;
      }
    },
  },
  {
    field: 'locationTo',
    headerName: 'Куда',
    minWidth: 150,
    flex: 0.2,
    valueGetter: (params: GridValueGetterParams) => {
      const location = JSON.parse(params.row.locationTo);
      return location.value;
    },
  },
];

const basicColumnsMobile: GridColDef[] = [
  {
    field: 'cdekNumber',
    headerName: 'Номер заказа',
    minWidth: 100,
    flex: 0.2,
    renderCell: (params) => customCell(params),
  },
  {
    field: 'status',
    headerName: 'статус заказа',
    minWidth: 100,
    flex: 0.2,
    valueGetter: (params) => {
      return (OrderStatus as any)[params.value];
    },
  },
  {
    field: 'locationTo',
    headerName: 'Куда',
    minWidth: 100,
    flex: 0.2,
    valueGetter: (params: GridValueGetterParams) => {
      const location = JSON.parse(params.row.locationTo);
      return location.value;
    },
  },
];

export const OrdersList = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const orders = useAppSelector(getOrders);
  const isLoading = useAppSelector(getIsLoadingOrdersStatus);
  const [isEditeMode, setIsEditeMode] = useState(false);
  const isDesktop = useResponsive('up', 'md', 'sm');
  const totalCount = useAppSelector(getTotalOrders);

  const handleProductListItemClick = useCallback(
    (params: GridRowParams): void => {
      setOpen(true);
      dispatch(setChangedOrder(params.row));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const fetchDataCallback = useCallback((page: number, limit: number) => {
    dispatch(fetchOrdersAction({ page, limit })); // получаем заказы
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchOrdersAction({}));
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          height: '600px',
          width: '100%',
          paddingTop: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Table
          columns={isDesktop ? basicColumns : basicColumnsMobile}
          fetchDataCallback={fetchDataCallback}
          totalCount={totalCount}
          handleProductListItemClick={handleProductListItemClick}
          items={orders}
          idField='cdekOrderId'
          isLoading={isLoading}
        />
      </div>

      <DetailOrderModal
        open={open}
        onClose={handleClose}
        isEditeMode={isEditeMode}
        setIsEditeMode={setIsEditeMode}
      />
      {isEditeMode && (
        <EditeOrderModal
          isEditeMode={isEditeMode}
          setIsEditeMode={setIsEditeMode}
        />
      )}
    </>
  );
};
