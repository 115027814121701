import { LoadingButton } from '@mui/lab';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getPrintFormPdfWebApp, printOrderForm } from 'services/api';
import {
  setPrintFormDocumentId,
  updateOrderPrintForm,
} from 'redux/OrderPageSlice/orderPageSlice';
import { Order } from 'types/order';
import Button from '@mui/material/Button';
import { socket } from 'services/socket';
import { handlePrintFormOpen } from 'utils/handlePrintFormOpen';
import { useTelegram } from 'hooks/useTelegram';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';

interface CreateOrderPrintFormButtonProps {
  changedOrder: Order;
}

const CreateOrderPrintFormButton = ({
  changedOrder,
}: CreateOrderPrintFormButtonProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { user } = useTelegram();

  useEffect(() => {
    socket.on('order:printFormReady', ({ cdekOrderId, printFormId }) => {
      dispatch(updateOrderPrintForm({ cdekOrderId, printFormId }));

      handlePrintFormOpen(printFormId).then((url) => {
        if (url) {
          setUrl(url);
        }
      });
    });

    return () => {
      socket.off('order:printFormReady');
    };
  }, [dispatch]);

  const fetchWaybillPdf = async () => {
    if (!changedOrder.printFormId) {
      await createPrintForm();
      return;
    }

    try {
      if (user) {
        await getPrintFormPdfWebApp(
          changedOrder.printFormId,
          changedOrder.cdekNumber,
          user.id
        );
        return;
      }
      const url = await handlePrintFormOpen(changedOrder.printFormId);
      setUrl(url);

      showSuccessNotification(
        'Заявка на создание накладной успешно отправлена!'
      );
    } catch (error: any) {
      if (error.response.status === 404) {
        await createPrintForm();
      }
    }
  };

  const createPrintForm = async () => {
    if (changedOrder) {
      setIsLoading(true);

      try {
        const id = await printOrderForm(changedOrder.cdekOrderId);

        if (id) {
          dispatch(
            setPrintFormDocumentId({
              cdekOrderId: changedOrder.cdekOrderId,
              printFormId: id,
            })
          );

          showSuccessNotification(
            'Заявка на создание накладной успешно отправлена!'
          );
        }
      } catch (error) {
        showErrorNotification('Заявка на создание накладной не отправлена!');
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <LoadingButton
        variant='outlined'
        color='info'
        onClick={fetchWaybillPdf}
        loading={isLoading}
      >
        Создать накладную
      </LoadingButton>
      {url && (
        <Button
          component='a'
          target='_blank'
          href={url}
          variant='text'
          color='success'
        >
          Скачать накладную по заказу
        </Button>
      )}
    </>
  );
};

export default memo(CreateOrderPrintFormButton);
