import { FormControl, Typography } from '@mui/material';
import CreateNewOrderDeliveryInfo from '../CreateNewOrderDeliveryInfo/CreateNewOrderDeliveryInfo';
import CreateNewOrderFinish from '../CreateNewOrderFinish/CreateNewOrderFinish';
import CreateNewOrderFormGeneralInfo from '../CreateNewOrderFormGeneralInfo/CreateNewOrderFormGeneralInfo';
import CreateNewOrderFormTariff from '../CreateNewOrderFormTariff/CreateNewOrderFormTariff';
import CreateNewOrderStepper from '../CreateNewOrderStepper/CreateNewOrderStepper';
import { memo, useEffect, useState } from 'react';
import { TariffType } from '../../../types/order';
import { createNewOrder, fetchCitiesSdekCode } from '../../../services/api';
import CreateFormSubmitButtons from './CreateFormSubmitButtons/CreateFormSubmitButtons';
import { TailSpin } from 'react-loader-spinner';
import { PaymentTypeList } from '../../../consts/order';
import CreateNewOrderPayment from '../CreateNewOrderPayment/CreateNewOrderPayment';
import {
  createOrderSlice,
  setPackages,
} from '../../../redux/CreateOrderSlice/createOrderSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  getLocationFrom,
  getLocationTo,
  getPackages,
} from '../../../redux/CreateOrderSlice/selector';
import { addNewOrder } from '../../../redux/OrderPageSlice/orderPageSlice';
import { CreateNewOrderBaseInfo } from '../CreateNewOrderBaseInfo/CreateNewOrderBaseInfo';
import { validationBaseInfo } from '../CreateNewOrderBaseInfo/validationBaseInfo';
import { validationGeneralInfo } from '../CreateNewOrderFormGeneralInfo/validationGeneralInfo';
import { validationTariff } from '../CreateNewOrderFormTariff/validationTariff';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';

const steps = ['Общая информация', 'Место доставки', 'Габариты', 'Тариф'];

type CreateNewOrderFormProps = {
  closeModal: () => void;
};

export interface ErrorsValidating {
  [index: string]: string;
}

const CreateNewOrderForm = ({ closeModal }: CreateNewOrderFormProps) => {
  const locationTo = useAppSelector(getLocationTo);
  const locationFrom = useAppSelector(getLocationFrom);
  const dispatch = useAppDispatch();
  const { setLocationTo, setLocationFrom } = createOrderSlice.actions;

  const [activeStep, setActiveStep] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [errorsPackages, setErrorsPackages] = useState<Record<string, any>>({});

  // General information fields
  const packages = useAppSelector(getPackages);
  const [isLoading, setIsLoading] = useState(false);

  // Tariff field
  const [tariff, setTariff] = useState<TariffType | null>(null);

  // Delivery details
  // const [type, setType] = useState<"entity" | "individual">("individual");
  const type = 'individual';
  const [nameRecipient, setNameRecipient] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  //Payment type
  const [paymentType, setPaymentType] = useState<PaymentTypeList>(
    PaymentTypeList.Free
  );
  const [priceCOD, setPriceCOD] = useState<number | ''>('');

  // clear error state if data changing
  useEffect(() => {
    clearErrorState();
  }, [
    locationTo,
    locationFrom,
    tariff,
    type,
    nameRecipient,
    phoneNumber,
    paymentType,
    priceCOD,
    packages,
  ]);

  const clearErrorState = () => {
    setDisableNext(false);
    setErrors({});
  };

  const clearDataState = () => {
    setActiveStep(0);
    dispatch(
      setLocationTo({
        value: '',
        fiasCode: '',
        sdekCityCode: null,
        postalCode: null,
        lat: '',
        lon: '',
      })
    );
    dispatch(
      setLocationFrom({
        value: '',
        fiasCode: '',
        sdekCityCode: null,
        postalCode: null,
        deliveryPointCode: '',
        deliveryPointName: '',
        deliveryPointAdress: '',
        deliveryPointOpenHours: '',
      })
    );
    setTariff(null);
    setNameRecipient('');
    setPhoneNumber('');
    dispatch(setPackages({}));
  };

  const submitBaseInfo = async () => {
    const { errors, isValid } = validationBaseInfo({
      locationFrom,
      locationTo,
      nameRecipient,
      phoneNumber,
    });

    if (!isValid && errors) {
      setErrors(errors);
      return false;
    }

    if (locationFrom?.fiasCode && locationTo?.fiasCode) {
      const { cityCodeFrom, cityCodeTo } = await fetchCitiesSdekCode({
        locationFromFiasCode: locationFrom?.fiasCode,
        locationToFiasCode: locationTo?.fiasCode,
      });

      setSdekCityCodes(cityCodeFrom, cityCodeTo);
    }

    return true;
  };

  const submitMapDeliveryPoint = () => {
    if (!locationTo.deliveryPointCode) {
      setErrors({ map: 'Выберете пункт вывоза товара' });
      return false;
    }

    return true;
  };

  const submitGeneralInfo = () => {
    if (!locationTo.dimensions) {
      return false;
    }

    const { isValid, errors } = validationGeneralInfo(
      packages,
      locationTo.dimensions
    );

    if (!isValid && errors) {
      setErrorsPackages(errors);
      return false;
    }

    return true;
  };

  const submitTypePayment = () => {
    const { isValid, errors } = validationTariff(paymentType, priceCOD, tariff);

    if (!isValid && errors) {
      setErrors(errors);
      return false;
    }

    return true;
  };

  const checkValidFields = async () => {
    let allowNext = true;

    if (activeStep === 0) {
      allowNext = await submitBaseInfo();
    }

    if (activeStep === 1 && allowNext) {
      allowNext = submitMapDeliveryPoint();
    }

    if (activeStep === 2 && allowNext) {
      allowNext = submitGeneralInfo();
    }

    if (activeStep === 3 && allowNext) {
      allowNext = submitTypePayment();
    }

    return allowNext;
  };

  const calcPriceCOD = (): number => {
    return paymentType === PaymentTypeList.DeliveryAndOrder
      ? Number(priceCOD)
      : 0;
  };

  const handleSubmitCreateNewOrder = async () => {
    if (await checkValidFields()) {
      setIsLoading(true);

      createNewOrder({
        locationTo,
        locationFrom,
        packages: Object.values(packages),
        tariff, // тариф с ценой доставки
        type,
        nameRecipient,
        phoneNumber,
        paymentType,
        priceCOD: calcPriceCOD(), //наложный платеж
      })
        .then(({ data }) => {
          showSuccessNotification('Заказ успешно создан!');
          dispatch(addNewOrder(data));
          closeModal();
        })
        .catch(() => {
          showErrorNotification('При создании заказа произошла ошибка!');
        })
        .finally(() => {
          setIsLoading(false);

          setTimeout(() => {
            clearErrorState();
            clearDataState();
          }, 3000);
        });
      return;
    }

    setActiveStep(0);
  };

  const handleNext = async () => {
    if (await checkValidFields()) {
      setActiveStep(activeStep + 1);
      return;
    }

    setDisableNext(true);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setSdekCityCodes = (cityCodeFrom: number, cityCodeTo: number) => {
    dispatch(
      setLocationFrom({
        sdekCityCode: cityCodeFrom,
      })
    );

    dispatch(
      setLocationTo({
        sdekCityCode: cityCodeTo,
      })
    );
  };

  if (isLoading) {
    return (
      <TailSpin
        color='#2274a5'
        wrapperStyle={{ margin: '300px', justifyContent: 'center' }}
      />
    );
  }

  console.log(locationTo, 'locationTo form ');

  return (
    <>
      <FormControl className='create-new-order__form'>
        <Typography
          variant='h5'
          component='h3'
          sx={{
            textAlign: 'center',
            marginBottom: '20px',
            textTransform: 'uppercase',
            paddingRight: 5,
            paddingLeft: 5,
          }}
        >
          {steps[activeStep] || 'Создание заказа'}
        </Typography>

        {activeStep === 0 && (
          <CreateNewOrderBaseInfo
            nameRecipient={nameRecipient}
            setNameRecipient={setNameRecipient}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            minLengthFetchingAdress={2}
            amountGetAdresses={10}
            setTariff={setTariff}
            errors={errors}
          />
        )}
        {activeStep === 1 && <CreateNewOrderDeliveryInfo errors={errors} />}

        {activeStep === 2 && (
          <CreateNewOrderFormGeneralInfo errors={errorsPackages} />
        )}

        {activeStep === 3 && (
          <>
            <CreateNewOrderPayment
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              priceCOD={priceCOD}
              setPriceCOD={setPriceCOD}
            />
            <CreateNewOrderFormTariff
              tariff={tariff}
              setTariff={setTariff}
              errors={errors}
              orderTariffData={{
                formatedSdekCityCodeTo: locationTo.sdekCityCode as number,
                formatedSdekCityCodeFrom: locationFrom.sdekCityCode as number,
                packages: Object.values(packages),
                pickupType: locationTo.pickupType,
              }}
            />
          </>
        )}

        {activeStep === steps.length && (
          <CreateNewOrderFinish
            tariff={tariff}
            type={type}
            nameRecipient={nameRecipient}
            phoneNumber={phoneNumber}
            setActiveStep={setActiveStep}
            paymentType={paymentType}
            priceCOD={priceCOD}
          />
        )}
      </FormControl>
      {activeStep !== steps.length ? (
        <CreateNewOrderStepper
          steps={steps}
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
          disableNext={disableNext}
        />
      ) : (
        <CreateFormSubmitButtons
          handleSubmitCreateNewOrder={handleSubmitCreateNewOrder}
          closeModal={() => {
            clearErrorState();
            clearDataState();
            closeModal();
          }}
        />
      )}
    </>
  );
};

export default memo(CreateNewOrderForm);
