import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { ApiRoutes } from '../../consts/api';
import { apiErrorHandler } from '../../services/apiErrorHandle';
import { AppDispatch, State } from '../../types/redux';
import {
  setIsLoadingUsersStatus,
  setUserPageError,
  setUserTotal,
  setUsers,
} from './userPageSlice';

interface FetchUsersActionI {
  page?: number;
  limit?: number;
}

export const fetchUsersAction = createAsyncThunk<
  void,
  FetchUsersActionI,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  'users/fetchUsers',
  async ({ page = 0, limit = 10 }, { dispatch, extra: api }) => {
    dispatch(setIsLoadingUsersStatus(true));
    dispatch(setUserPageError(''));
    try {
      const { data } = await api.get(ApiRoutes.User, {
        params: {
          page,
          limit,
        },
      });

      if (data?.users) {
        dispatch(setUserTotal(data.total));
        dispatch(setUsers(data.users));
        dispatch(setIsLoadingUsersStatus(false));
      }
    } catch (error) {
      dispatch(setUserPageError(apiErrorHandler(error)));
      dispatch(setIsLoadingUsersStatus(false));
    }
  }
);
