import IconButton from '@mui/material/IconButton';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Marker } from 'react-map-gl';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setLocationTo } from '../../redux/CreateOrderSlice/createOrderSlice';
import { propertyOf } from 'lodash';
import { getLocationTo } from '../../redux/CreateOrderSlice/selector';
import { getOrderDimensions } from '../../helpers/order';

type CustomMarkerProps = {
  pointData: any;
  children?: JSX.Element;
};

export const CustomMarker = ({ pointData, children }: CustomMarkerProps) => {
  const locationTo = useAppSelector(getLocationTo);
  const dispatch = useAppDispatch();

  const handleMarkerClick = () => {
    const orderDimensions = getOrderDimensions(pointData);    

    dispatch(
      setLocationTo({
        deliveryPointCode: pointData.code,
        deliveryPointName: pointData.name,
        deliveryPointAdress: pointData.location.address,
        deliveryPointOpenHours: pointData.work_time,
        lat: pointData.location.latitude,
        lon: pointData.location.longitude,
        dimensions: orderDimensions,
        pickupType: pointData.type,
      })
    );
  };

  return (
    <Marker
      // @ts-ignore
      offsetTop={-48}
      offsetLeft={-24}
      latitude={propertyOf(pointData)(['location', 'latitude'])}
      longitude={propertyOf(pointData)(['location', 'longitude'])}
    >
      <IconButton
        onClick={handleMarkerClick}
        color={
          pointData.code === locationTo.deliveryPointCode ? 'error' : 'primary'
        }
      >
        {children}
        <FmdGoodIcon />
      </IconButton>
    </Marker>
  );
};
