import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router";
import redirectToPage from "../../services/redirectToPage";

export interface ConfirmDialogProps {
  open: boolean;
  setOpen: (isOpen: boolean)=> void
  reportId: string;
  title: string;
}

const CustomButton = styled(Button)({
  textTransform: "none",
});

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const navigate = useNavigate();
  const { open, reportId, title, setOpen } = props;

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <CustomButton
            fullWidth
            onClick={() => setOpen(false)}
            variant="outlined"
          >
            Назад к списку отчетов
          </CustomButton>
          <CustomButton
            fullWidth
            autoFocus
            onClick={() => redirectToPage(`reports/${reportId}`, "_blanc", "noreferrer")}
            variant="contained"
          >
            Посмотреть отчет
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
