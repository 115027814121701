import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type CloseButtonModalProps = {
  onClose: () => void;
};

const CloseButtonModal = ({ onClose }: CloseButtonModalProps) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 15,
        color: (theme) => theme.palette.error.main,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButtonModal;
