import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { ApiRoutes } from '../../consts/api';
import { apiErrorHandler } from '../../services/apiErrorHandle';
import { AppDispatch, State } from '../../types/redux';
import {
  setOrderError,
  setIsLoadingOrdersStatus,
  setOrders,
  setTotalOrders,
} from './orderPageSlice';

export const fetchOrdersAction = createAsyncThunk<
  void,
  { page?: number; limit?: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  'orders/fetchOrders',
  async ({ page = 0, limit = 10 }, { dispatch, extra: api }) => {
    dispatch(setIsLoadingOrdersStatus(true));
    dispatch(setOrderError(''));
    try {
      const { data } = await api.get(ApiRoutes.GetOrders, {
        params: { page, limit },
      });

      if (data?.orders) {
        dispatch(setTotalOrders(data.total));
        dispatch(setIsLoadingOrdersStatus(false));
        dispatch(setOrders(data.orders));
      }
    } catch (error) {
      dispatch(setIsLoadingOrdersStatus(false));
      dispatch(setOrderError(apiErrorHandler(error)));
    }
  }
);
