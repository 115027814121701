import { State } from "./../../types/redux";

export const getReports = (state: State) => state.report.reports;

export const getIsLoadingReportsStatus = (state: State): boolean =>
  state.report.isLoading;

export const getReportsError = (state: State): string => state.report.error;

export const getTotalReports = (state: State): number =>
  state.report.total;
