import { Order } from '../../types/order';
import { State } from '../../types/redux';

export const getChangedOrder = (state: State): Order | null =>
  state.orderPage.changedOrder;

export const getOrders = (state: State) => state.orderPage.orders;

export const getIsLoadingOrdersStatus = (state: State): boolean =>
  state.orderPage.isLoadingOrders;

export const getOrderPageError = (state: State): string =>
  state.orderPage.error;

export const getTotalOrders = (state: State): number =>
  state.orderPage.totalOrders;
