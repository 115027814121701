import { Autocomplete, Grid, Stack, TextField } from '@mui/material';
import { memo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { IAutocomplitingAddress, TariffType } from '../../../types/order';
import {
  setLocationFrom,
  setLocationTo,
} from '../../../redux/CreateOrderSlice/createOrderSlice';
import { getAdress } from '../../../services/api';
import _ from 'lodash';
import { RecipientInfo } from './RecipientInfo';

interface CreateNewOrderBaseInfoProps {
  nameRecipient: string;
  setNameRecipient: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  minLengthFetchingAdress: number;
  amountGetAdresses: number;
  setTariff: (tariff: TariffType | null) => void;
  errors: Record<string, string>;
}

export interface IValuesGeneralInfo {
  locationFrom: IAutocomplitingAddress | null;
  locationTo: IAutocomplitingAddress | null;
}

export const CreateNewOrderBaseInfo = memo(
  ({
    nameRecipient,
    setNameRecipient,
    setPhoneNumber,
    phoneNumber,
    minLengthFetchingAdress,
    amountGetAdresses,
    setTariff,
    errors,
  }: CreateNewOrderBaseInfoProps) => {
    const { locationTo, locationFrom } = useAppSelector((state) => state.order);
    const [adressesListFrom, setAdressesListFrom] = useState<string[]>([]);
    const [adressesListTo, setAdressesListTo] = useState<string[]>([]);
    const dispatch = useAppDispatch();

    // RF - убрать прокидывание функций, брать их из слайса
    const onChangeAdress = async (
      value: string,
      setAdressesList: Function,
      direction: string
    ) => {
      let data: string[] = [];
      const mathedValue =
        direction === 'from'
          ? adressesListFrom?.find((el: any) => el.value === value)
          : adressesListTo?.find((el: any) => el.value === value);

      if (mathedValue) {
        direction === 'from'
          ? dispatch(setLocationFrom(mathedValue))
          : dispatch(setLocationTo(mathedValue));
        setTariff(null);
      }

      if (value.length > minLengthFetchingAdress) {
        data = await getAdress(value, amountGetAdresses);
      }
      setAdressesList(data);
    };

    const onChangeAdressFromDebounce = _.debounce(onChangeAdress, 300);
    const onChangeAdressToDebounce = _.debounce(onChangeAdress, 300);

    const onChangeLocationFromText = (
      evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      onChangeAdressFromDebounce(evt.target.value, setAdressesListFrom, 'from');
    };

    const onChangeLocationFromAutocomplete = async (
      event: React.SyntheticEvent<Element, Event>,
      value: string
    ) => {
      dispatch(
        setLocationFrom({
          deliveryPointAdress: '',
          deliveryPointOpenHours: '',
        })
      );
      onChangeAdressFromDebounce(value, setAdressesListFrom, 'from');
    };

    const onChangeLocationToText = (
      evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      onChangeAdressToDebounce(evt.target.value, setAdressesListTo, 'to');
    };

    const onChangeLocationToAutocomplete = async (
      event: React.SyntheticEvent<Element, Event>,
      value: string
    ) => {
      dispatch(
        setLocationTo({
          deliveryPointCode: '',
          deliveryPointName: '',
          deliveryPointAdress: '',
          deliveryPointOpenHours: '',
        })
      );
      onChangeAdressToDebounce(value, setAdressesListTo, 'to');
    };

    return (
      <Stack spacing={3} flexDirection='column'>
        <Grid item container justifyContent='space-between' spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              disablePortal
              filterOptions={(x) => x}
              id='from'
              options={adressesListFrom.map((el: any) => el.value)}
              value={locationFrom.value}
              onChange={onChangeLocationFromAutocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Откуда'
                  fullWidth
                  onChange={onChangeLocationFromText}
                  error={Boolean(errors.locationFrom)}
                  helperText={errors.locationFrom}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              disablePortal
              filterOptions={(x) => x}
              id='from'
              options={adressesListTo.map((el: any) => el.value)}
              value={locationTo.value}
              onChange={onChangeLocationToAutocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Куда'
                  fullWidth
                  onChange={onChangeLocationToText}
                  error={Boolean(errors.locationTo)}
                  helperText={errors.locationTo}
                />
              )}
            />
          </Grid>
        </Grid>
        <RecipientInfo
          nameRecipient={nameRecipient}
          setNameRecipient={setNameRecipient}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          errors={errors}
        />
      </Stack>
    );
  }
);
