import {
  IAutocomplitingAddress,
  PackagesSettingType,
  TariffType,
} from '../../../types/order';
import { useState } from 'react';
import { PaymentTypeList } from '../../../consts/order';
import BaseInfoAccordion from './BaseInfoAccordion/BaseInfoAccordion';
import TariffInfoAccordion from './TariffInfoAccordion/TariffInfoAccordion';
import DeliveryPointAccordion from './DeliveryPointAccordion/DeliveryPointAccordion';
import DimensionProductAccordion from './DimensionProductAccordion/DimensionProductAccordion';

type CreateNewOrderFinishProps = {
  type: 'entity' | 'individual';
  nameRecipient: string;
  phoneNumber: string;
  tariff: TariffType | null;
  setActiveStep: (value: number) => void;
  paymentType: PaymentTypeList;
  priceCOD: number | '';
};

const CreateNewOrderFinish = ({
  type,
  nameRecipient,
  phoneNumber,
  tariff,
  paymentType,
  setActiveStep,
  priceCOD,
}: CreateNewOrderFinishProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <BaseInfoAccordion
        type={type}
        nameRecipient={nameRecipient}
        phoneNumber={phoneNumber}
        handleChangeAccordion={handleChangeAccordion}
        expanded={expanded}
        setActiveStep={setActiveStep}
      />

      <DeliveryPointAccordion
        handleChangeAccordion={handleChangeAccordion}
        expanded={expanded}
        setActiveStep={setActiveStep}
      />

      <DimensionProductAccordion
        handleChangeAccordion={handleChangeAccordion}
        expanded={expanded}
        setActiveStep={setActiveStep}
      />

      <TariffInfoAccordion
        tariff={tariff}
        paymentType={paymentType}
        priceCOD={priceCOD}
        handleChangeAccordion={handleChangeAccordion}
        expanded={expanded}
        setActiveStep={setActiveStep}
      />
    </>
  );
};

export default CreateNewOrderFinish;
