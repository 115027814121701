import { Box, List } from "@mui/material";
import { NavLinkType } from "../../../types/navigation";
import { WithAdminAccess } from "../../hoc/WithAdminAccess";
import NavItemLink from "../NavItemLink/NavItemLink";

type NavSectionProps = {
  linkList: NavLinkType[];
};

export default function NavSection({ linkList }: NavSectionProps) {
  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {linkList.map((link) => {
          if (link.isAdminAccess) {
            const NavItemLinkWithAdminAccess = WithAdminAccess(
              NavItemLink,
              false
            );
            return <NavItemLinkWithAdminAccess key={link.title} link={link} />;
          }
          return <NavItemLink key={link.title} link={link} />;
        })}
      </List>
    </Box>
  );
}
