import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TypePersonOrder } from '../../../../consts/order';
import { useAppSelector } from '../../../../hooks/redux';
import {
  getLocationFrom,
  getLocationTo,
} from '../../../../redux/CreateOrderSlice/selector';

type BaseInfoAccordionProps = {
  nameRecipient: string;
  phoneNumber: string;
  type: 'entity' | 'individual';

  setActiveStep: (value: number) => void;
  expanded: string | boolean;
  handleChangeAccordion: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const BaseInfoAccordion = ({
  handleChangeAccordion,
  expanded,
  setActiveStep,
  nameRecipient,
  phoneNumber,
  type,
}: BaseInfoAccordionProps) => {
  const locationTo = useAppSelector(getLocationTo);
  const locationFrom = useAppSelector(getLocationFrom);

  return (
    <>
      <Accordion
        expanded={expanded === 'general-info'}
        onChange={handleChangeAccordion('general-info')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='general-info'
          id='general-info'
        >
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            sx={{ width: '100%' }}
          >
            <Typography
              className='create-new-order__finish-title'
              variant='h5'
              component='h5'
            >
              Общая информация
            </Typography>

            <Button onClick={() => setActiveStep(0)}>Изменить</Button>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            className='create-new-order__finish'
            spacing={2}
            flexDirection='column'
            component='ul'
            sx={{ padding: 0, margin: 0, listStyle: 'none' }}
          >
            <li>
              <b>Откуда: </b>
              <span>{locationFrom?.value}</span>
            </li>
            <li>
              <b>Куда: </b>
              <span>{locationTo?.value}</span>
            </li>
            <li>
              <b>Тип: </b>
              <span>{TypePersonOrder[type]}</span>
            </li>
            <li>
              <b>ФИО/Компания: </b>
              <span>{nameRecipient}</span>
            </li>
            <li>
              <b>Телефон: </b>
              <span>{phoneNumber}</span>
            </li>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BaseInfoAccordion;
