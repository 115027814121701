import GetIcon from "../../SvgColor/GetIcon";

const navConfig = [
  {
    title: "Заказы",
    path: "/",
    icon: GetIcon("ic_analytics"),
    isAdminAccess: false,
  },
  {
    title: "Пользователи",
    path: "/users",
    icon: GetIcon("ic_user"),
    isAdminAccess: true,
  },
  {
    title: "Отчеты",
    path: "/reports",
    icon: GetIcon("ic_report"),
    isAdminAccess: true,
  },
];

export default navConfig;
