import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserType } from '../../types/user';

type UserPageSliceType = {
  users: UserType[];
  isLoadingUsers: boolean;
  error: string;
  total: number;
};

const initialState: UserPageSliceType = {
  users: [],
  isLoadingUsers: false,
  error: '',
  total: 0,
};

const userPageSlice = createSlice({
  name: 'UserPage',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setIsLoadingUsersStatus: (state, action) => {
      state.isLoadingUsers = action.payload;
    },
    setUserPageError: (state, action) => {
      state.error = action.payload;
    },
    resetUserPageError: (state, action) => {
      state.error = action.payload;
    },
    setUserTotal(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
  },
});

export default userPageSlice.reducer;

export const {
  setUsers,
  setIsLoadingUsersStatus,
  setUserPageError,
  resetUserPageError,
  setUserTotal
} = userPageSlice.actions;
