import { useEffect, useState } from 'react';
import { Box, Modal, Typography, Toolbar } from '@mui/material';
import CloseButtonModal from '../CloseIconModal/CloseIconModal';
import { useFormik, FormikProps } from 'formik';
import { Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getChangedOrder } from '../../redux/OrderPageSlice/selector';
import {
  IAutocomplitingAddress,
  IUpdateOrderData,
  Order,
  TariffType,
  TariffTypeCamel,
} from '../../types/order';
import CreateNewOrderPayment from '../CreateNewOrder/CreateNewOrderPayment/CreateNewOrderPayment';
import { PaymentTypeList } from '../../consts/order';
import {
  setLocationFrom,
  setLocationTo,
} from '../../redux/CreateOrderSlice/createOrderSlice';
import CreateNewOrderDeliveryInfo from '../CreateNewOrder/CreateNewOrderDeliveryInfo/CreateNewOrderDeliveryInfo';
import { updateOrder } from '../../services/api';
import { getLocationTo } from '../../redux/CreateOrderSlice/selector';
import {
  replaceOrder,
  setChangedOrder,
} from '../../redux/OrderPageSlice/orderPageSlice';
import { validationSchemaEditeOrderForm } from './validateEditeOrderForm';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { IconButton } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { RecipientInfo } from '../CreateNewOrder/CreateNewOrderBaseInfo/RecipientInfo';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/notification';

type EditeOrderModalProps = {
  isEditeMode: boolean;
  setIsEditeMode: (isEditeMode: boolean) => void;
};

const EditeOrderModal = ({
  isEditeMode,
  setIsEditeMode,
}: EditeOrderModalProps) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(getChangedOrder) as Order;
  const locationTo = useAppSelector(getLocationTo);

  const tariffToObj: TariffTypeCamel = JSON.parse(order.tariff);
  const locationFromObj: IAutocomplitingAddress = JSON.parse(
    order.locationFrom
  );
  const locationToObj: IAutocomplitingAddress = JSON.parse(order?.locationTo);

  const setSdekCityCodes = (cityCodeFrom: number, cityCodeTo: number) => {
    dispatch(
      setLocationFrom({
        sdekCityCode: cityCodeFrom,
      })
    );

    dispatch(
      setLocationTo({
        sdekCityCode: cityCodeTo,
      })
    );
  };

  useEffect(() => {
    dispatch(
      setLocationTo({
        value: locationToObj.value,
        sdekCityCode: locationToObj.sdekCityCode,
        deliveryPointCode: locationToObj.deliveryPointCode,
        deliveryPointName: locationToObj.deliveryPointName,
        deliveryPointAdress: locationToObj.deliveryPointAdress,
        deliveryPointOpenHours: locationToObj.deliveryPointOpenHours,
        lat: locationToObj.lat,
        lon: locationToObj.lon,
        fiasCode: locationToObj.fiasCode,
      })
    );
  }, []);

  interface ValuesEditeOrderForm {
    nameRecipient: string;
    phoneNumber: string;
    paymentType: PaymentTypeList;
    priceCOD: number;
    tariff: TariffTypeCamel | TariffType;
  }

  const formikEditeOrder: FormikProps<ValuesEditeOrderForm> =
    useFormik<ValuesEditeOrderForm>({
      initialValues: {
        nameRecipient: order.nameRecipient,
        phoneNumber: order.phoneNumber,
        paymentType: order.paymentType,
        priceCOD: order.priceCOD,
        tariff: tariffToObj,
      },
      validationSchema: validationSchemaEditeOrderForm,
      onSubmit: async () => {
        const updateOrderData: IUpdateOrderData = {
          cdekOrderId: order.cdekOrderId,
        };
        if (
          order.nameRecipient !== formikEditeOrder.values.nameRecipient ||
          order.phoneNumber !== formikEditeOrder.values.phoneNumber
        ) {
          updateOrderData.name = formikEditeOrder.values.nameRecipient;
          updateOrderData.phoneNumber = formikEditeOrder.values.phoneNumber;
        }
        if (order.priceCOD !== formikEditeOrder.values.priceCOD) {
          updateOrderData.priceCOD = formikEditeOrder.values.priceCOD;
        }
        if (
          order.paymentType !== formikEditeOrder.values.paymentType &&
          formikEditeOrder.values.paymentType === PaymentTypeList.Free
        ) {
          updateOrderData.priceCOD = 0;
        }
        if (
          'tariff_code' in formikEditeOrder.values.tariff &&
          tariffToObj.tariffCode !== formikEditeOrder.values.tariff.tariff_code
        ) {
          updateOrderData.tariff = formikEditeOrder.values.tariff as TariffType;
        }
        if (locationToObj.deliveryPointCode !== locationTo.deliveryPointCode) {
          updateOrderData.locationTo = locationTo;
        }

        try {
          const updatedOrder = await updateOrder(updateOrderData);
          dispatch(
            replaceOrder({
              cdekOrderId: order.cdekOrderId,
              order: updatedOrder,
            })
          );
          dispatch(setChangedOrder(updatedOrder));
          showSuccessNotification('Изменения сохранены');
        } catch (error) {
          showErrorNotification('Изменения не сохранены');
        }
      },
    });

  const handleChangePaymentType = (value: PaymentTypeList) => {
    formikEditeOrder.setFieldValue('paymentType', value);
  };

  const handleChangePriceCOD = (value: number | '') => {
    formikEditeOrder.setFieldValue('priceCOD', value);
  };

  const handleChangeNameRecipient = (value: string) => {
    formikEditeOrder.setFieldValue('nameRecipient', value);
  };

  const handleChangePhoneNumber = (value: string) => {
    formikEditeOrder.setFieldValue('phoneNumber', value);
  };

  const handleSubmit = () => {
    formikEditeOrder.handleSubmit();
  };

  const onClose = () => {
    dispatch(
      setLocationTo({
        value: '',
        fiasCode: '',
        sdekCityCode: null,
        postalCode: null,
        lat: '',
        lon: '',
        deliveryPointCode: '',
        deliveryPointName: '',
        deliveryPointAdress: '',
        deliveryPointOpenHours: '',
      })
    );
    setIsEditeMode(false);
  };

  return (
    <>
      {order && (
        <Modal
          open={isEditeMode}
          onClose={onClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              paddingTop: '20px',
              width: '800px',
              maxHeight: '90vh',
              overflow: 'hidden',
              minHeight: '500px',
              borderRadius: '10px',
            }}
          >
            <AppBar
              position='sticky'
              component='nav'
              sx={{
                borderRadius: '10px 10px 0 0',
              }}
            >
              <Toolbar>
                <Typography variant='h5' sx={{ ml: '35px' }}>
                  {/* Редактирование заказа ({order?.packageNumber}): */}
                </Typography>
                <IconButton
                  size='medium'
                  onClick={handleSubmit}
                  sx={{
                    position: 'absolute',
                    left: 8,
                    top: 10,
                    border: '12px',
                    color: yellow[300],
                  }}
                >
                  <SaveAsOutlinedIcon />
                </IconButton>

                <CloseButtonModal onClose={onClose} />
              </Toolbar>
            </AppBar>
            <Container
              fixed
              sx={{
                position: 'relative',
                padding: '20px',
                background: '#fff',
                width: '100%',
                maxHeight: '85vh',
                overflow: 'scroll',
                minHeight: '500px',
              }}
            >
              <form onSubmit={formikEditeOrder.handleSubmit}>
                <Stack spacing={3}>
                  <CreateNewOrderPayment
                    paymentType={formikEditeOrder.values.paymentType}
                    setPaymentType={handleChangePaymentType}
                    priceCOD={formikEditeOrder.values.priceCOD}
                    setPriceCOD={handleChangePriceCOD}
                  />
                  {/* // todo поправить костыль с ошибками (выпилить формик) */}
                  <RecipientInfo
                    nameRecipient={formikEditeOrder.values.nameRecipient}
                    setNameRecipient={handleChangeNameRecipient}
                    setPhoneNumber={handleChangePhoneNumber}
                    phoneNumber={formikEditeOrder.values.phoneNumber}
                    errors={formikEditeOrder.errors as Record<string, string>}
                  />
                  {/* // todo поправить костыль с ошибками (выпилить формик) */}
                  <CreateNewOrderDeliveryInfo
                    errors={formikEditeOrder.errors as Record<string, string>}
                  />
                </Stack>
              </form>
            </Container>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditeOrderModal;
