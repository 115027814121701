import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from '../../../../hooks/redux';
import { getPackages } from '../../../../redux/CreateOrderSlice/selector';

type DimensionProductAccordionProps = {
  setActiveStep: (value: number) => void;
  expanded: string | boolean;
  handleChangeAccordion: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const DimensionProductAccordion = ({
  handleChangeAccordion,
  expanded,
  setActiveStep,
}: DimensionProductAccordionProps) => {
  const packages = useAppSelector(getPackages);

  return (
    <>
      <Accordion
        expanded={expanded === 'delivery-info'}
        onChange={handleChangeAccordion('delivery-info')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='delivery-info'
          id='delivery-info'
        >
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            sx={{ width: '100%' }}
          >
            <Typography
              className='create-new-order__finish-title'
              variant='h5'
              component='h5'
            >
              Габариты товаров
            </Typography>

            <Button onClick={() => setActiveStep(2)}>Изменить</Button>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            className='create-new-order__finish'
            spacing={2}
            flexDirection='column'
            component='ul'
            sx={{ padding: 0, margin: 0, listStyle: 'none' }}
          >
            {Object.values(packages).map((packageSetting, index) => (
              <li key={packageSetting.id}>
                <Stack direction={'row'} spacing={1}>
                  <b>Место № </b>
                  <span>{index + 1}</span>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <b>Вес (кг): </b>
                  <span>{packageSetting.weight}</span>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <b>Габариты (ШхВхД)см: </b>
                  <span>{`${packageSetting.width}x${packageSetting.height}x${packageSetting.length}`}</span>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <b>Документы: </b>
                  <span>{packageSetting.document}</span>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                  <b>Номер места: </b>
                  <span>{packageSetting.packageNumber}</span>
                </Stack>
              </li>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DimensionProductAccordion;
