import { Button, Typography, Link, Stack } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';
import { PaymentTypeListText, TypePersonOrder } from '../../consts/order';
import { useAppSelector } from '../../hooks/redux';
import { getChangedOrder } from '../../redux/OrderPageSlice/selector';
import { IAutocomplitingAddress, TariffObjType } from '../../types/order';
import Barcode from '../Barcode/Barcode';
import BasicModal from '../BasicModal/BasicModal';
import Waybill from '../Waybill/Waybill';
import ItemDetailWithAdminAccess from './ItemDetailWithAdminAccess';
import { deleteOrder } from 'services/api';
import { showSuccessNotification } from 'utils/notification';
import { useState } from 'react';
import { DeleteOrderModal } from './DeleteOrderModal/DeleteOrderModal';

type DetailOrderModalProps = {
  open: boolean;
  onClose: () => void;
  isEditeMode: boolean;
  setIsEditeMode: (isEditeMode: boolean) => void;
};

//TODO: надо разбить компоненту и логику, слишком толстая стала
const DetailOrderModal = ({
  open,
  onClose,
  setIsEditeMode,
}: DetailOrderModalProps) => {
  const order = useAppSelector(getChangedOrder);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  if (order === null) {
    return null;
  }

  const {
    tariff,
    cdekNumber,
    locationTo,
    nameRecipient,
    type,
    paymentType,
    priceCOD,
    deliveryPrice,
    phoneNumber,
    userName,
    status,
    packages,
  } = order;

  let locationFrom = order.locationFrom as string;

  const tariffObj: TariffObjType = JSON.parse((tariff as any) || '{}');
  const locationToObj: IAutocomplitingAddress = JSON.parse(
    (locationTo as any) || '{}'
  );

  try {
    const locationFromObj: IAutocomplitingAddress = JSON.parse(
      (locationFrom as any) || '{}'
    );
    locationFrom = locationFromObj.value;
  } catch {}

  const handleEditeOrder = () => {
    setIsEditeMode(true);
  };

  const handleDeleteOrder = async () => {
    const result = await deleteOrder(order.cdekOrderId);

    if (result) {
      showSuccessNotification('Заказ успешно удален');
      onClose();
    }
  };

  if (!order) {
    return (
      <TailSpin
        color='#2274a5'
        wrapperStyle={{ margin: '300px', justifyContent: 'center' }}
      />
    );
  }

  return (
    <>
      <BasicModal modalState={open} onClose={onClose}>
        <Stack>
          <Typography variant='h5' sx={{ mb: 2 }}>
            {/* Детали заказа ({packageNumber}): */}
          </Typography>
          <Stack>
            <Typography variant='body1'>
              <b>Номер заказа:&nbsp;</b>
              <Link
                href={`https://www.cdek.ru/ru/tracking?order_id=${cdekNumber}`}
                target='_blank'
                rel='noreferrer'
              >
                {cdekNumber}
              </Link>
            </Typography>

            <ItemDetailWithAdminAccess term='Поставщик' definition={userName} />

            <Typography variant='body1'>
              <b>Куда:&nbsp;</b>
              <span>
                {locationToObj.value} {locationToObj.deliveryPointAdress}{' '}
                {locationToObj.deliveryPointCode}
              </span>
            </Typography>

            <Typography variant='body1'>
              <b>Откуда:&nbsp;</b>
              <span>{locationFrom}</span>
            </Typography>

            <Typography variant='body1'>
              <b>Тип оплаты:&nbsp;</b>
              <span>{PaymentTypeListText[paymentType]}</span>
            </Typography>

            <Typography variant='body1'>
              <b>Сумма наложенного платежа:&nbsp;</b>
              <span>{priceCOD}</span>
            </Typography>

            <Typography variant='body1'>
              <b>Тариф:&nbsp;</b>
              <span>{tariffObj?.tariffName}</span>
            </Typography>

            <Typography variant='body1'>
              <b>Цена:&nbsp;</b>
              <span>{deliveryPrice} руб.</span>
            </Typography>

            <Typography variant='body1'>
              <b>Время:&nbsp;</b>
              <span>
                {tariffObj?.periodMin} - {tariffObj?.periodMax} дней
              </span>
            </Typography>

            <Typography variant='body1'>
              <b>Имя получателя:&nbsp;</b>
              <span>
                {nameRecipient} {TypePersonOrder[type]}
              </span>
            </Typography>

            <Typography variant='body1' mb='10px'>
              <b>Контактный номер:&nbsp;</b>
              <span>{phoneNumber}</span>
            </Typography>

            <Stack direction={'row'} flexWrap='wrap'>
              {Object.values(packages).map((packageSetting, index) => (
                <Stack spacing={0} flexWrap='wrap' ml='20px' mb={'20px'}>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant='body1'>
                      <b>Место № {index + 1}</b>
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <b>Вес (кг): </b>
                    <span>{packageSetting.weight}</span>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <b>Габариты (ШхВхД)см: </b>
                    <span>{`${packageSetting.width}x${packageSetting.height}x${packageSetting.length}`}</span>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <b>Документы: </b>
                    <span>{packageSetting.document}</span>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <b>Номер места: </b>
                    <span>{packageSetting.packageNumber}</span>
                  </Stack>
                </Stack>
              ))}
            </Stack>

            <Stack direction={'column'} gap={'20px'}>
              {/* {userRole === "ADMIN" ? (
                <>
                  <Button
                    onClick={handlePaymentButtonClick}
                    variant="outlined"
                    color="success"
                    sx={{
                      mt: "10px",
                    }}
                    disabled={isOrderPayed ? true : false}
                  >
                    заказ оплачен
                  </Button>
                </>
              ) : null} */}
              <Waybill changedOrder={order} />
              <Barcode changedOrder={order} />

              {status === 'CREATED' && (
                <Button onClick={handleEditeOrder} variant='contained'>
                  Редактировать заказ
                </Button>
              )}

              {status === 'CREATED' && (
                <Button
                  onClick={() => setOpenDeleteModal(true)}
                  variant='contained'
                  color='error'
                >
                  Удалить заказ
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
        <DeleteOrderModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onDeleteOrder={handleDeleteOrder}
          cdekNumber={order.cdekNumber}
        />
      </BasicModal>
    </>
  );
};

export default DetailOrderModal;
