import { FormLabel, TextField } from "@mui/material";

type CreateNewOrderTotalPriceProps = {
  priceCOD: "" | number;
  setPriceCOD: (value: number | "") => void;
  isError: boolean;
};

const CreateNewOrderTotalPrice = ({
  priceCOD,
  setPriceCOD,
  isError,
}: CreateNewOrderTotalPriceProps) => {
  return (
    <>
      <FormLabel>Стоимость отправления:</FormLabel>
      <TextField
        label="Сумма"
        value={priceCOD}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]",
          onWheel: (evt) => evt.currentTarget.blur(),
        }}
        onChange={(evt) => setPriceCOD(Number(evt.target.value))}
        error={isError && priceCOD === ""}
        helperText={isError && !priceCOD && "Это поле обязательное!"}
      />
    </>
  );
};

export default CreateNewOrderTotalPrice;
