import {
  ruRU,
  GridColDef,
  GridToolbarExport,
  DataGrid,
  GridToolbarContainer,
  GridValueFormatterParams,
  GridRowParams,
  gridClasses,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrdersReport } from "../../../services/api";
import { Order } from "../../../types/order";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";
import { WithAdminAccess } from "../../hoc/WithAdminAccess";
import { propertyOf } from "lodash";
import { Container, Stack, Typography } from "@mui/material";
import { ReportSettingType } from "../../../types/report";
import { CdekReportPage } from "./CdekReportPage";

function CustomToolbar(sellerName: string, reportOptions: ReportSettingType) {
  return (
    <GridToolbarContainer>
      <Stack direction={"row"} gap={"20px"}>
        <Typography sx={{ my: "20px" }}>Поставщик: {sellerName}</Typography>
        <Typography sx={{ my: "20px" }}>
          Дата формирования: {moment().format("DD.MM.YYYY")}
        </Typography>
        <Typography sx={{ my: "20px" }}>
          Диапазон: {moment(reportOptions.dateFrom).format("DD.MM.YYYY")} -{" "}
          {moment(reportOptions.dateTo).format("DD.MM.YYYY")}
        </Typography>
        <GridToolbarExport
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{
            hideFooter: true,
            hideToolbar: false,
            copyStyles: true,
          }}
        />
      </Stack>
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "№ п/п",
    minWidth: 50,
    flex: 0.05,
    filterable: false,
    renderCell: (index) => index.api.getRowIndex(index.row.cdekNumber) + 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cdekNumber",
    headerName: "№ накладной",
    minWidth: 150,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
  },

  {
    field: "createdAt",
    headerName: "Дата накладной",
    minWidth: 150,
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      const date = moment(params.value);
      return date.format("DD.MM.YYYY");
    },
    headerAlign: "center",
    flex: 0.12,
    align: "center",
  },
  {
    field: "dateDelivered",
    headerName: "Дата доставки",
    minWidth: 150,
    flex: 0.12,
    align: "center",
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      const date = moment(params.value);
      return date.format("DD.MM.YYYY");
    },
    headerAlign: "center",
  },
  {
    field: "nameRecipient",
    headerName: "Получатель",
    flex: 0.15,
    minWidth: 150,
    headerAlign: "center",
  },
  {
    field: "deliveryPrice",
    headerName: "Стоимость основной услуги (руб)",
    minWidth: 150,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "priceCOD",
    headerName: "Сумма наложенного платежа (руб)",
    minWidth: 150,
    flex: 0.12,
    headerAlign: "center",
    align: "center",
  },
];

export const ReportPage = () => {
  const { reportId } = useParams();
  const [sellerName, setSellerName] = useState("");
  const [reportOptions, setReportOptions] = useState<ReportSettingType | {}>(
    {}
  );
  const [currentReportOrders, setCurrentReportOrders] = useState<
    Order[] | null
  >(null);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState("");

  useEffect(() => {
    if (!reportId) {
      return;
    }
    getOrdersReport(reportId).then((data) => {
      setSellerName(propertyOf(data)(["report", "reportUserName"]));
      setCurrentReportOrders(data?.orders);
      setReportOptions(JSON.parse(propertyOf(data)(["report", "settings"])));
    });
  }, []);

  const handleReportListItemClick = async (params: GridRowParams) => {
    setSelectedOrderNumber(params.row.cdekNumber);
  };

  return currentReportOrders ? (
    <Container sx={{ width: "100%", maxWidth: "1280px", m: "0 auto" }}>
      <div style={{ display: "flex", height: "100vh" }}>
        <div style={{ flexGrow: 1, paddingBottom: "30px" }}>
          <CdekReportPage
            selectedOrderNumber={selectedOrderNumber}
            currentReportOrders={currentReportOrders}
          />
          <DataGrid
            rows={currentReportOrders}
            columns={columns}
            rowsPerPageOptions={[]}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Toolbar: () =>
                CustomToolbar(sellerName, reportOptions as ReportSettingType),
            }}
            autoHeight
            getRowId={(row) => row.cdekNumber}
            onRowClick={handleReportListItemClick}
            getRowHeight={() => "auto"}
            initialState={{
              sorting: {
                sortModel: [{ field: "dateDelivered", sort: "desc" }],
              },
            }}
            sx={{
              "@media print": {
                ".MuiDataGrid-main": { overflow: "visible", contain: "size", zoom: "0.92" },
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-cell:click": {
                color: "primary.main",
              },
              "& .MuiDataGrid-row:hover": {
                color: "primary.main",
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell": {
                height: "100px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
              [`& .${gridClasses.cell}`]: {
                py: "10px",
              },
            }}
          />
        </div>
      </div>
    </Container>
  ) : (
    <TailSpin
      color="#2274a5"
      wrapperStyle={{ margin: "300px", justifyContent: "center" }}
    />
  );
};

export default WithAdminAccess(ReportPage, true);
