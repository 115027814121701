import { TokenData } from "./../types/token";
import { propertyOf } from "lodash";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import redirectToPage from "./redirectToPage";

const accessTokenController = {
  save: (token: string) => {
    const decodedToken: any = jwtDecode(token);
    localStorage.setItem("userId", propertyOf(decodedToken)(["userId"]));
    localStorage.setItem("role", propertyOf(decodedToken)(["role"]));
    Cookies.set("access_token", token);
  },

  remove: () => {
    Cookies.remove("access_token");
  },

  isCurrentUserAdmin() {
    const token = this.getToken();
    const decodedToken: any = jwtDecode(token as string);
    if (decodedToken?.role === "ADMIN") {
      return true;
    }

    return false;
  },

  decodeToken(token: string): TokenData {
    return jwtDecode(token);
  },

  checkAuth() {
    const accessToken = this.getToken();
    if (
      !accessToken &&
      propertyOf(window)(["location", "pathname"]) !== "/login"
    ) {
      redirectToPage("login", "_self");
      return;
    }
    return accessToken;
  },

  logout: () => {
    Cookies.remove("access_token");
    if (propertyOf(window)(["location", "pathname"]) !== "/login") {
      redirectToPage("login", "_self");
    }
  },

  getToken: () => {
    const accessToken = Cookies.get("access_token");
    return accessToken || null;
  },
};

export default accessTokenController;
