import { Box, Modal } from '@mui/material';
import CreateNewOrderForm from '../CreateNewOrderForm/CreateNewOrderForm';
import CloseButtonModal from '../../CloseIconModal/CloseIconModal';

type CreateNewOrderModalProps = {
  onClose: () => void;
  open: boolean;
};

const CreateNewOrderModal = ({ onClose, open }: CreateNewOrderModalProps) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Modal
        className='modalCreateOrder'
        open={open}
        onClose={closeModal}
        sx={{ top: '10vh', height: '80vh' }}
      >
        <Box
          sx={{
            position: 'relative',
            padding: '20px',
            background: '#fff',
            overflowY: 'scroll',
            minHeight: '300px',
            borderRadius: '10px',
            display: 'grid',
            gridTemplateRows: '1fr 100px',
            maxWidth: 700,
          }}
        >
          <CreateNewOrderForm closeModal={closeModal} />

          <CloseButtonModal onClose={closeModal} />
        </Box>
      </Modal>
    </>
  );
};

export default CreateNewOrderModal;
