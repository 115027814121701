import { useMap } from "react-map-gl";
import { Autocomplete, Box, TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getLocationTo, getOfficeListLocationTo } from "../../redux/CreateOrderSlice/selector";
import { setLocationTo } from "../../redux/CreateOrderSlice/createOrderSlice";
import { getOrderDimensions } from "../../helpers/order";

const PickpointList = () => {
  const dispatch = useAppDispatch();
  const officeListLocationTo = useAppSelector(getOfficeListLocationTo);
  const locationTo = useAppSelector(getLocationTo);

  const { PickpointMap } = useMap();  

  const handleSearchChange = (event: any, newValue: any) => {
    const pointData = officeListLocationTo?.find(
      (el) => el.location.address === newValue
    );

    if (!pointData) {
      return;
    }

    const orderDimensions = getOrderDimensions(pointData);    

    dispatch(
      setLocationTo({
        deliveryPointCode: pointData.code,
        deliveryPointName: pointData.name,
        deliveryPointAdress: pointData.location.address,
        deliveryPointOpenHours: pointData.work_time,
        lat: pointData.location.latitude,
        lon: pointData.location.longitude,
        dimensions: orderDimensions,
        pickupType: pointData.type,
      })
    );

    PickpointMap?.flyTo({center: [Number(pointData.location.longitude), Number(pointData.location.latitude)]});
    
  };

  if (!officeListLocationTo) {
    return null;
  }

  return (
    <Box
      sx={{
        mt: "20px",
        mb: "20px",
      }}
    >
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        value={locationTo?.deliveryPointAdress}
        options={officeListLocationTo?.map((option) => option.location.address)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Найти"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onChange={handleSearchChange}
      />
    </Box>
  );
};

export default PickpointList;
