export type UserType = {
  role: string;
  userId: string;
  name: string;
  note: string;
  id: number;
  login: string;
  password: string;
};

export type CreatingUserType = {
  name: string;
  note: string;
};

export enum UserLoginStatusType {
  Unknown = "Unknown",
  Authorized = "Authorized",
  Unauthorized = "Unauthorized",
}
