import { Link as RouterLink } from "react-router-dom";
import { Box, Link, SxProps, Theme } from "@mui/material";

type LogoProps = {
  disabledLink?: boolean;
  sx?: SxProps<Theme>;
};

const Logo = ({ disabledLink = false, sx }: LogoProps) => {
  const logo = (
    <Box
      component="div"
      sx={{
        display: "inline-flex",
        fontSize: '20px',
        textAlign: 'center',
        ...sx,
      }}
    >
      <p>СДЭК ИНТЕГРАЦИЯ</p>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
};

export default Logo;
