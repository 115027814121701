import { AxiosInstance } from "axios";
import { State } from "./../../types/redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../types/redux";
import { ApiRoutes } from "../../consts/api";
import accessTokenController from "../../services/accessTokenController";
import { UserLoginStatusType } from "../../types/user";
import {
  setProfileDataToken,
  setUserAuthStatus,
  setUserError,
} from "./userSlice";
import { apiErrorHandler } from "../../services/apiErrorHandle";

export const loginUserAction = createAsyncThunk<
  void,
  { login: string; password: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("user/loginUser", async ({ login, password }, { dispatch, extra: api }) => {
  dispatch(setUserError(""));
  try {
    const { data } = await api.post(ApiRoutes.SignIn, {
      login,
      password,
    });

    if (data) {
      accessTokenController.save(data.accessToken);
      const tokenData = accessTokenController.decodeToken(data.accessToken);
      
      dispatch(setProfileDataToken(tokenData));
      dispatch(setUserAuthStatus(UserLoginStatusType.Authorized));
    }
  } catch (error) {
    dispatch(setUserError(apiErrorHandler(error)));
  }
});
