import "mapbox-gl/dist/mapbox-gl.css";
import { Box } from "@mui/material";
import Map, { MapProvider, NavigationControl } from "react-map-gl";

import { useAppSelector } from "../../hooks/redux";
import { CustomMarkerList } from "./CustomMarkerList";
import { getLocationTo } from "../../redux/CreateOrderSlice/selector";
import PickpointList from "../PickpointList/PickpointList";

const WidgetMap = () => {
  // Получим активный ПВЗ и его координаты
  const { lat, lon } = useAppSelector(getLocationTo);

  return (
    <Box>
      <MapProvider>
        <PickpointList />
        <Map
          scrollZoom={false}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            longitude: Number(lon),
            latitude: Number(lat),
            zoom: 12,
          }}
          id="PickpointMap"
          style={{ width: "100%", height: 400, paddingTop: "25px" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onClick={(evt) =>
            evt.target.flyTo({
              center: [evt.lngLat.lng, evt.lngLat.lat],
            })
          }
        >
          {/* @ts-ignore */}
          <CustomMarkerList />
          <NavigationControl />
        </Map>
      </MapProvider>
    </Box>
  );
};

export default WidgetMap;
