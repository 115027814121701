import { Button, Stack } from "@mui/material";

type CreateFormSubmitButtonsProps = {
  closeModal: () => void;
  handleSubmitCreateNewOrder: () => void;
};

const CreateFormSubmitButtons = ({
  handleSubmitCreateNewOrder,
  closeModal,
}: CreateFormSubmitButtonsProps) => {
  return (
    <Stack justifyContent="space-between" direction={'row'} gap={"20px"} sx={{
      height: '50px',
      mt: 'auto',
    }}>
      <Button variant="outlined" color="warning" fullWidth onClick={closeModal}>
        Отменить
      </Button>
      <Button
        variant="outlined"
        color="success"
        type="submit"
        onClick={handleSubmitCreateNewOrder}
        fullWidth
      >
        Создать
      </Button>
    </Stack>
  );
};

export default CreateFormSubmitButtons;
