import { forwardRef } from "react";
import { Icon } from "@iconify/react";
import { Box, SxProps, Theme } from "@mui/material";

type IconifyProps = {
  sx?: SxProps<Theme>;
  width?: number;
  icon: string;
};

const Iconify = forwardRef(({ icon, width = 20, sx }: IconifyProps, ref) => (
  <Box
    ref={ref}
    component={Icon}
    icon={icon}
    sx={{ width, height: width, ...sx }}
  />
));

export default Iconify;
