import { useState } from "react";
import { TextField, Typography, Button, Stack } from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import BasicModal from "../BasicModal/BasicModal";
import NavItemButton from "../NavItemButton/NavItemButton";
import { createNewUser } from "../../services/api";
import { UserType } from "../../types/user";

const CreateNewUser = () => {
  const [open, setOpen] = useState(false);
  const [nameValue, setNameValue] = useState<string>("");
  const [noteValue, setNoteValue] = useState<string>("");
  const [nameValueError, setNameValueError] = useState<string | null>(null);

  const validateNameTextField = (value: string) => {
    if (!value) {
      setNameValueError("Это поле обязательное!");
      return;
    }
    setNameValueError(null);
  };

  const handleModalClose = () => {
    setOpen(false);
    setNameValue("");
    setNameValueError(null);
  };

  const handleUserCreateButtonClick = () => {
    validateNameTextField(nameValue);
    if (!nameValueError && nameValue) {
      createNewUser({ name: nameValue, note: noteValue }).then(() => {
        window.location.reload();
      });
    }
  };

  const onChangeName = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNameValue(evt.target.value);
    validateNameTextField(evt.target.value);
  };

  return (
    <>
      <NavItemButton
        title="Новый пользователь"
        onOpen={() => setOpen(true)}
        open={open}
      />
      {open && (
        <BasicModal modalState={open} onClose={handleModalClose}>
          <Typography variant="h5">Новый пользователь</Typography>
          <FormContainer
            defaultValues={{ name: "", note: "" }}
            onSuccess={handleUserCreateButtonClick}
          >
            <Stack
              gap={"20px"}
              direction={"column"}
              sx={{
                mt: "20px",
              }}
            >
              <TextField
                name="name"
                label="Название поставщика"
                required
                fullWidth
                value={nameValue}
                onChange={onChangeName}
                error={Boolean(nameValueError)}
                helperText={Boolean(nameValueError) && nameValueError}
              />
              <TextFieldElement
                name="note"
                label="Комментарий"
                multiline
                rows={6}
                value={noteValue}
                onChange={(evt) => {
                  setNoteValue(evt.target.value);
                }}
                fullWidth
              />
              <Button type="submit" variant="contained" fullWidth>
                Создать
              </Button>
            </Stack>
          </FormContainer>
        </BasicModal>
      )}
    </>
  );
};

export default CreateNewUser;
