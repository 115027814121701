import { Typography } from "@mui/material";
import { WithAdminAccess } from "../hoc/WithAdminAccess";

export type ItemDetailOrderListProps = {
  term: string;
  definition: string;
};

const ItemDetailWithAdminAccess = ({term, definition}: ItemDetailOrderListProps) => {
  return (
    <Typography variant="body1">
      <b>{term}:&nbsp;</b>
      <span>{definition}</span>
    </Typography>
  );
};

export default WithAdminAccess(ItemDetailWithAdminAccess, false);


